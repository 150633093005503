import React from "react";

function TermsOfService() {
  return (
    <div className="min-h-screen bg-black text-white p-4">
      <header className="text-center mb-8">
        <h1 className="text-3xl font-bold mb-4">Terms of Service</h1>
        <p className="text-gray-400">Last updated: {new Date().toLocaleDateString()}</p>
      </header>
      
      <section className="max-w-3xl mx-auto space-y-6">
        <div>
          <h2 className="text-xl font-bold mb-2 text-orange-500">1. Introduction</h2>
          <p className="mb-2">
            Welcome to AVNT. These Terms of Service ("Terms") govern your access to and use of the AVNT website, mobile applications, and any other online services (collectively, the "Services") provided by AVNT, Inc. ("AVNT," "we," "us," or "our").
          </p>
          <p>
            By accessing or using our Services, you agree to be bound by these Terms. If you do not agree to these Terms, you may not access or use the Services. These Terms constitute a legally binding agreement between you and AVNT, Inc.
          </p>
        </div>

        <div>
          <h2 className="text-xl font-bold mb-2 text-orange-500">2. Eligibility</h2>
          <p className="mb-2">
            You must be at least 13 years old to use our Services. If you are under 18, you must have your parent or guardian's permission to use our Services, and they must agree to these Terms on your behalf.
          </p>
          <p>
            By using the Services, you represent and warrant that you meet all eligibility requirements.
          </p>
        </div>

        <div>
          <h2 className="text-xl font-bold mb-2 text-orange-500">3. Account Registration</h2>
          <p className="mb-2">
            To access certain features of our Services, you may need to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.
          </p>
          <p className="mb-2">
            You are responsible for safeguarding your account credentials and for any activity that occurs under your account. You agree to notify us immediately of any unauthorized access to your account.
          </p>
          <p>
            We reserve the right to disable any user account at any time in our sole discretion, including if we believe you have violated these Terms.
          </p>
        </div>

        <div>
          <h2 className="text-xl font-bold mb-2 text-orange-500">4. User Content</h2>
          <p className="mb-2">
            Our Services may allow you to post, submit, publish, display, or transmit content such as reviews, comments, and other materials (collectively, "User Content").
          </p>
          <p className="mb-2">
            You retain ownership rights in your User Content. However, by posting User Content, you grant AVNT, Inc. a perpetual, worldwide, non-exclusive, royalty-free, transferable, sublicensable license to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, perform, display, and otherwise exploit your User Content in connection with the Services and AVNT, Inc.'s (and its successors' and affiliates') business, including for promoting and redistributing part or all of the Services.
          </p>
          <p>
            You represent and warrant that: (i) you own or control all rights in and to your User Content or have the right to grant the license above; (ii) your User Content does not violate any third party rights, including intellectual property rights and rights of publicity and privacy; (iii) your User Content complies with these Terms and all applicable laws; and (iv) your User Content does not violate any AVNT, Inc. policies.
          </p>
        </div>

        <div>
          <h2 className="text-xl font-bold mb-2 text-orange-500">5. Prohibited Conduct</h2>
          <p className="mb-2">You agree not to:</p>
          <ul className="list-disc list-inside mb-4 text-gray-300 space-y-1">
            <li>Use the Services in any manner that could interfere with, disrupt, or negatively affect the Services</li>
            <li>Use the Services for any illegal or unauthorized purpose</li>
            <li>Attempt to circumvent any content-filtering techniques we employ</li>
            <li>Attempt to access or search the Services through the use of automated means</li>
            <li>Attempt to gain unauthorized access to any part of the Services</li>
            <li>Post or transmit any content that is unlawful, fraudulent, or infringes third-party rights</li>
            <li>Impersonate any person or entity or misrepresent your affiliation with a person or entity</li>
            <li>Engage in any harassing, intimidating, or abusive conduct</li>
            <li>Use the Services to send any unsolicited or unauthorized advertising</li>
          </ul>
        </div>

        <div>
          <h2 className="text-xl font-bold mb-2 text-orange-500">6. E-Commerce and Transactions</h2>
          <p className="mb-2">
            Our Services may allow you to purchase products or services. All purchases are subject to our order acceptance. We reserve the right to refuse or cancel any order for any reason.
          </p>
          <p className="mb-2">
            Product descriptions, images, and prices are subject to change without notice. We do not guarantee that product descriptions or other content will be accurate, complete, reliable, current, or error-free.
          </p>
          <p>
            You are responsible for paying all fees and taxes associated with your purchases. Payment must be made at the time of purchase using an approved payment method.
          </p>
        </div>

        <div>
          <h2 className="text-xl font-bold mb-2 text-orange-500">7. Shipping and Returns</h2>
          <p className="mb-2">
            Shipping times are estimates and not guarantees. We are not responsible for delays caused by customs, shipping carriers, or other factors outside our control.
          </p>
          <p>
            Our return policy will be provided at the time of purchase. Some products may not be eligible for return. For eligible products, returns must be initiated within the specified return window and items must be in their original condition.
          </p>
        </div>

        <div>
          <h2 className="text-xl font-bold mb-2 text-orange-500">8. Intellectual Property Rights</h2>
          <p className="mb-2">
            The Services and their contents, including but not limited to text, graphics, images, logos, and software, are owned by AVNT or its licensors and are protected by copyright, trademark, and other intellectual property laws.
          </p>
          <p>
            You may not use, reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, or transmit any of the material on our Services, except as necessary for your personal, non-commercial use.
          </p>
        </div>

        <div>
          <h2 className="text-xl font-bold mb-2 text-orange-500">9. Third-Party Links and Services</h2>
          <p className="mb-2">
            Our Services may contain links to third-party websites or services. We are not responsible for the content or practices of any third-party websites or services and do not endorse them.
          </p>
          <p>
            Your use of third-party websites or services is subject to the terms and conditions of those websites or services.
          </p>
        </div>

        <div>
          <h2 className="text-xl font-bold mb-2 text-orange-500">10. Disclaimer of Warranties</h2>
          <p className="mb-2">
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE SERVICES AND ALL CONTENT THEREON ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE.
          </p>
          <p className="mb-2">
            WITHOUT LIMITING THE FOREGOING, AVNT, INC. DOES NOT WARRANT THAT: (I) THE SERVICES OR ANY PRODUCTS OR CONTENT THEREON WILL BE UNINTERRUPTED, SECURE, OR ERROR-FREE; (II) THE SERVICES OR ANY CONTENT THEREON WILL MEET YOUR REQUIREMENTS; (III) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE SERVICES WILL MEET YOUR EXPECTATIONS; OR (IV) ANY ERRORS IN THE SERVICES WILL BE CORRECTED. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM AVNT, INC. OR THROUGH THE SERVICES WILL CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN.
          </p>
          <p>
            YOU ACKNOWLEDGE THAT AVNT, INC. HAS NO CONTROL OVER, AND NO DUTY TO TAKE ANY ACTION REGARDING: WHICH USERS GAIN ACCESS TO THE SERVICES; WHAT CONTENT YOU ACCESS VIA THE SERVICES; WHAT EFFECTS THE CONTENT MAY HAVE ON YOU; HOW YOU MAY INTERPRET OR USE THE CONTENT; OR WHAT ACTIONS YOU MAY TAKE AS A RESULT OF HAVING BEEN EXPOSED TO THE CONTENT.
          </p>
        </div>

        <div>
          <h2 className="text-xl font-bold mb-2 text-orange-500">11. Limitation of Liability</h2>
          <p className="mb-2">
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL AVNT, INC., ITS DIRECTORS, EMPLOYEES, PARTNERS, AGENTS, SUPPLIERS, OR AFFILIATES, BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS, DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM: (I) YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE SERVICES; (II) ANY CONDUCT OR CONTENT OF ANY THIRD PARTY ON THE SERVICES, INCLUDING WITHOUT LIMITATION, ANY DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF OTHER USERS OR THIRD PARTIES; (III) ANY CONTENT OBTAINED FROM THE SERVICES; (IV) UNAUTHORIZED ACCESS, USE, OR ALTERATION OF YOUR TRANSMISSIONS OR CONTENT; OR (V) ANY OTHER MATTER RELATING TO THE SERVICES, EVEN IF AVNT, INC. HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
          </p>
          <p className="mb-2">
            IN NO EVENT SHALL AVNT, INC.'S TOTAL LIABILITY TO YOU FOR ALL CLAIMS RELATING TO THE SERVICES EXCEED THE AMOUNT PAID BY YOU TO AVNT, INC., IF ANY, FOR ACCESSING OR USING THE SERVICES DURING THE TWELVE (12) MONTHS PRIOR TO THE CLAIM OR ONE HUNDRED DOLLARS ($100), WHICHEVER IS GREATER.
          </p>
          <p>
            SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IN SUCH CASES, AVNT, INC.'S LIABILITY WILL BE LIMITED TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW.
          </p>
        </div>

        <div>
          <h2 className="text-xl font-bold mb-2 text-orange-500">12. Indemnification</h2>
          <p className="mb-2">
            You agree to defend, indemnify, and hold harmless AVNT, Inc., its parent company, subsidiaries, affiliates, and their respective directors, officers, employees, agents, service providers, contractors, licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to:
          </p>
          <ul className="list-disc list-inside mb-4 text-gray-300 space-y-1">
            <li>Your violation of these Terms</li>
            <li>Your use of the Services, including, but not limited to, any User Content submitted by you</li>
            <li>Your violation of any rights of another person or entity, including intellectual property rights and privacy rights</li>
            <li>Your violation of any applicable laws, rules, or regulations</li>
            <li>Any claim that your User Content caused damage to a third party</li>
            <li>Any misrepresentation made by you</li>
          </ul>
          <p>
            AVNT, Inc. reserves the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate with our defense of these claims. You agree not to settle any matter without the prior written consent of AVNT, Inc.
          </p>
        </div>

        <div>
          <h2 className="text-xl font-bold mb-2 text-orange-500">13. Modifications to the Services</h2>
          <p>
            We reserve the right to modify or discontinue, temporarily or permanently, the Services or any features or portions thereof without prior notice. You agree that we will not be liable for any modification, suspension, or discontinuance of the Services.
          </p>
        </div>

        <div>
          <h2 className="text-xl font-bold mb-2 text-orange-500">14. Changes to These Terms</h2>
          <p className="mb-2">
            We may revise these Terms from time to time. The most current version will always be posted on our website. If a revision, in our sole discretion, is material, we will notify you via email to the email address associated with your account or through the Services.
          </p>
          <p>
            By continuing to access or use our Services after revisions become effective, you agree to be bound by the revised Terms. If you do not agree to the new Terms, you must stop using the Services.
          </p>
        </div>

        <div>
          <h2 className="text-xl font-bold mb-2 text-orange-500">15. Termination</h2>
          <p className="mb-2">
            We may terminate or suspend your access to the Services immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach these Terms.
          </p>
          <p>
            Upon termination, your right to use the Services will immediately cease. All provisions of these Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.
          </p>
        </div>

        <div>
          <h2 className="text-xl font-bold mb-2 text-orange-500">16. Governing Law and Dispute Resolution</h2>
          <p className="mb-2">
            These Terms shall be governed by and construed in accordance with the laws of the State of California, without regard to its conflict of law provisions.
          </p>
          <p className="mb-2">
            Any dispute, controversy, or claim arising out of or relating to these Terms, including the validity, invalidity, breach, or termination thereof, shall be finally settled by arbitration in San Francisco, California, using the English language in accordance with the Arbitration Rules and Procedures of JAMS then in effect, by one commercial arbitrator with substantial experience in resolving intellectual property and commercial contract disputes.
          </p>
          <p className="mb-2">
            You agree that any arbitration shall be limited to the dispute between us and you individually. To the full extent permitted by law:
          </p>
          <ul className="list-disc list-inside mb-4 text-gray-300 space-y-1">
            <li>No arbitration shall be joined with any other proceeding</li>
            <li>There is no right or authority for any dispute to be arbitrated on a class-action basis</li>
            <li>There is no right or authority for any dispute to be brought in a purported representative capacity</li>
          </ul>
          <p className="mb-2">
            YOU UNDERSTAND AND HEREBY AGREE THAT YOU ARE WAIVING YOUR RIGHT TO SUE OR GO TO COURT TO ASSERT OR DEFEND YOUR RIGHTS. YOU AND AVNT, INC. ARE EACH WAIVING THE RIGHT TO A JURY TRIAL AND THE RIGHT TO PARTICIPATE IN A CLASS ACTION.
          </p>
          <p>
            Notwithstanding the foregoing, you and AVNT, Inc. both agree that nothing herein will be deemed to waive, preclude, or otherwise limit either party's right to seek injunctive or other equitable relief in a court of law for matters relating to intellectual property rights, obligations of confidentiality, or to prevent the actual or threatened infringement, misappropriation, or violation of a party's copyrights, trademarks, trade secrets, patents, or other intellectual property rights.
          </p>
        </div>

        <div>
          <h2 className="text-xl font-bold mb-2 text-orange-500">17. Contact Information</h2>
          <p>
            If you have any questions about these Terms, please contact us at <a href="mailto:legal@avnt.space" className="text-orange-500 hover:text-orange-400">legal@avnt.space</a>.
          </p>
        </div>
      </section>
    </div>
  );
}

export default TermsOfService; 