import React from 'react';

const MetricCard = ({ title, value, description, icon }) => {
  return (
    <div className="bg-gray-700 rounded-lg p-6 shadow-lg">
      <div className="flex justify-between items-start">
        <div>
          <h3 className="text-xl font-semibold text-white mb-1">{title}</h3>
          <div className="text-3xl font-bold text-white my-2">{value}</div>
          <p className="text-gray-300 text-sm">{description}</p>
        </div>
        {icon && (
          <div className="text-3xl bg-gray-600 p-3 rounded-full">
            {icon}
          </div>
        )}
      </div>
    </div>
  );
};

export default MetricCard; 