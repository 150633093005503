// src/History.js
import React from "react";

function History() {
  return (
    <div className="min-h-screen bg-black text-white p-4">
      <header className="text-center mb-8">
        <h1 className="text-3xl font-bold mb-4">Our History</h1>
      </header>
      <section className="max-w-3xl mx-auto">
        <p className="text-lg mb-4 italic">
          To see the light, one must first experience darkness.
        </p>
        <p className="text-lg mb-4">
          We believe that the next creative revolution started in the early 2010's with our OG's and mentors FTP, Virgil Abloh, and others pioneering the creation of next-generation brands.
        </p>
        <p className="text-lg mb-4">
          This continued to grow with Supreme's continued rise and growing popularity by 2016-2017, and the advent of next-generation brands such as Half Evil and others.
        </p>
        <p className="text-lg mb-4">
          The inspiration for AVNT came from being in early internet communities and seeing the launch of these brands firsthand.
        </p>
        <p className="text-lg mb-4">
          The hottest underground streetwear brands at the time were making pieces that were as cool, if not cooler than the biggest brands in the world, but they lacked a platform to help them fight through the noise on social media and grow.
        </p>
        <p className="text-lg mb-4">
          AVNT was launched in 2019 as a platform to help the best underground streetwear brands grow.
        </p>
        <p className="text-lg mb-4">
          It began by reaching out to the brands we believed in and doing interviews that we'd post on the AVNT website and AVNT Instagram 3x/week.
        </p>
        <p className="text-lg mb-4">
          Soon, we realized that there were drops happening nearly every day, so we started covering those on the website and social media as well, and the community continued to grow.
        </p>
        <p className="text-lg mb-4">
          We continued building relationships and the AVNT community and space continued to grow.
        </p>
        <p className="text-lg mb-4">
          Within the first year, the space grew massively with hundreds of thousands of people joining AVNT's community and the communities of the brands AVNT showcased.
        </p>
        <p className="text-lg mb-4">
          We've continued to learn and grow every step of the way.
        </p>
        <p className="text-lg mb-4">
          We've had some great years, and some tough years as well.
        </p>
        <p className="text-lg mb-4">
          But the vision never wavered, and our optimism for the future never wavered.
        </p>
        <p className="text-lg mb-4">
          The future for creatives around the world has never been brighter,
        </p>
        <p className="text-lg">
          and it's only the beginning.
        </p>
      </section>
    </div>
  );
}

export default History;