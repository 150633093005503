import React from 'react';

const UserTable = ({ users, loading, caption, shopColumn = false }) => {
  if (loading) {
    return <div className="text-center py-8 text-gray-400">Loading user data...</div>;
  }

  if (!users || users.length === 0) {
    return <div className="text-center py-8 text-gray-400">No user data available</div>;
  }

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-gray-700 rounded-lg overflow-hidden">
        <caption className="sr-only">{caption}</caption>
        <thead className="bg-gray-600">
          <tr>
            <th className="px-4 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Rank</th>
            <th className="px-4 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">User</th>
            {shopColumn ? (
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Shop Clicks</th>
            ) : (
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Engagement Score</th>
            )}
            <th className="px-4 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Last Active</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-600">
          {users.map((user, index) => (
            <tr key={user.id} className={index % 2 === 0 ? 'bg-gray-700' : 'bg-gray-750'}>
              <td className="px-4 py-3 whitespace-nowrap">{index + 1}</td>
              <td className="px-4 py-3 whitespace-nowrap">
                <div className="flex items-center">
                  <div className="flex-shrink-0 h-8 w-8 rounded-full bg-gray-600 flex items-center justify-center text-sm">
                    {user.username ? user.username.charAt(0).toUpperCase() : 'U'}
                  </div>
                  <div className="ml-3">
                    <div className="text-sm font-medium text-white">{user.username}</div>
                    <div className="text-xs text-gray-400">{user.email}</div>
                  </div>
                </div>
              </td>
              <td className="px-4 py-3 whitespace-nowrap">
                <div className="text-sm text-white">
                  {shopColumn ? user.totalShopClicks.toLocaleString() : user.engagementScore.toLocaleString()}
                </div>
              </td>
              <td className="px-4 py-3 whitespace-nowrap">
                <div className="text-sm text-gray-300">
                  {user.lastActive ? new Date(user.lastActive).toLocaleDateString() : 'Unknown'}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserTable; 