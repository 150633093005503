import React, { useState, useEffect } from 'react';
import { collection, query, where, orderBy, limit, getDocs, getFirestore, Timestamp } from 'firebase/firestore';
import ProductTable from './components/ProductTable';

const ProductMetrics = ({ timeFrame }) => {
  const [products, setProducts] = useState([]);
  const [totalActiveProducts, setTotalActiveProducts] = useState(0);
  const [loading, setLoading] = useState(true);
  const [sortField, setSortField] = useState('trendScore'); // Default sort by trend score
  
  // Sorting options
  const sortOptions = [
    { value: 'trendScore', label: 'Trend Score' },
    { value: 'shopLinkClicks', label: 'Shop Clicks' },
    { value: 'impressions', label: 'Views' },
    { value: 'expandedViewClicks', label: 'Expanded Views' }
  ];
  
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const db = getFirestore();
        
        // Determine which trend score field to use based on timeFrame
        let trendScoreField = 'trendScore'; // default fallback
        
        if (timeFrame === 'daily') {
          trendScoreField = 'runningTrendScore24h';
        } else if (timeFrame === 'weekly') {
          trendScoreField = 'runningTrendScore7d';
        } else if (timeFrame === 'monthly') {
          trendScoreField = 'runningTrendScore30d';
        }
        
        // Get total active products count
        const activeProductsQuery = query(
          collection(db, 'allProducts'),
          where('status', '==', 'ACTIVE')
        );
        
        const activeProductsSnapshot = await getDocs(activeProductsQuery);
        setTotalActiveProducts(activeProductsSnapshot.size);
        
        // Get products sorted by the selected field (or appropriate trend score if trend score is selected)
        const sortByField = sortField === 'trendScore' ? trendScoreField : sortField;
        
        const productsQuery = query(
          collection(db, 'allProducts'),
          orderBy(sortByField, 'desc'),
          limit(25) // Increased limit since we're showing multiple sections
        );
        
        const productDocs = await getDocs(productsQuery);
        const productsData = [];
        
        productDocs.forEach(doc => {
          const productData = doc.data();
          productsData.push({
            id: doc.id,
            title: productData.title || 'Unnamed Product',
            brand: productData.vendor || 'Unknown Brand',
            impressions: productData.impressions || 0,
            expandedViewClicks: productData.expandedViewClicks || 0,
            shopLinkClicks: productData.shopLinkClicks || 0,
            // Use the appropriate trend score for display
            trendScore: productData[trendScoreField] || 0,
            image: productData.images?.[0] || null,
            productLink: productData.productLink || '#'
          });
        });
        
        setProducts(productsData);
      } catch (error) {
        console.error('Error fetching product metrics:', error);
      }
      setLoading(false);
    };
    
    fetchData();
  }, [timeFrame, sortField]);
  
  // Determine the metric label based on timeFrame
  const getTrendScoreLabel = () => {
    switch(timeFrame) {
      case 'daily': return 'Daily Trend Score (24h)';
      case 'weekly': return 'Weekly Trend Score (7d)';
      case 'monthly': return 'Monthly Trend Score (30d)';
      default: return 'Trend Score';
    }
  };
  
  // Get metric label based on current sort field
  const getMetricLabel = () => {
    if (sortField === 'trendScore') return getTrendScoreLabel();
    return sortOptions.find(option => option.value === sortField)?.label || 'Metric';
  };
  
  return (
    <div>
      <h2 className="text-2xl font-bold mb-6">Product Metrics</h2>
      
      {/* Total Active Products */}
      <div className="bg-gray-700 rounded-lg p-4 mb-6">
        <div className="text-xl font-semibold text-avnt-orange">Total Active Products</div>
        <div className="text-3xl font-bold">{totalActiveProducts}</div>
      </div>
      
      {/* Sorting options */}
      <div className="mb-4 flex items-center">
        <span className="mr-4">Sort By:</span>
        <div className="flex space-x-2 flex-wrap">
          {sortOptions.map(option => (
            <button 
              key={option.value}
              className={`px-4 py-2 rounded my-1 ${sortField === option.value ? 'bg-avnt-orange text-black' : 'bg-gray-800'}`}
              onClick={() => setSortField(option.value)}
            >
              {option.label}
            </button>
          ))}
        </div>
      </div>
      
      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-4">
          Top Products by {getMetricLabel()}
        </h3>
        <ProductTable 
          products={products} 
          loading={loading}
          caption={`Top products ranked by ${getMetricLabel().toLowerCase()} (${timeFrame})`}
          metricColumn={sortField}
          metricLabel={getMetricLabel()}
          showShopClicks={true}
        />
      </div>
    </div>
  );
};

export default ProductMetrics; 