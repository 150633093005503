import React from 'react';

const DropTable = ({ drops, loading, caption, metricColumn = 'trendScore', metricLabel = 'Engagement' }) => {
  if (loading) {
    return <div className="text-center py-8 text-gray-400">Loading drop data...</div>;
  }

  if (!drops || drops.length === 0) {
    return <div className="text-center py-8 text-gray-400">No drop data available</div>;
  }

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-gray-700 rounded-lg overflow-hidden">
        <caption className="sr-only">{caption}</caption>
        <thead className="bg-gray-600">
          <tr>
            <th className="px-4 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Rank</th>
            <th className="px-4 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Drop</th>
            <th className="px-4 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Brand</th>
            <th className="px-4 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">{metricLabel}</th>
            <th className="px-4 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Date</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-600">
          {drops.map((drop, index) => (
            <tr key={drop.id} className={index % 2 === 0 ? 'bg-gray-700' : 'bg-gray-750'}>
              <td className="px-4 py-3 whitespace-nowrap">{index + 1}</td>
              <td className="px-4 py-3 whitespace-nowrap">
                <div className="flex items-center">
                  {drop.image && (
                    <div className="flex-shrink-0 h-10 w-10 rounded bg-gray-600">
                      <img
                        className="h-10 w-10 rounded object-cover"
                        src={drop.image}
                        alt={drop.title}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = 'https://via.placeholder.com/40?text=AVNT';
                        }}
                      />
                    </div>
                  )}
                  <div className="ml-3">
                    <div className="text-sm font-medium text-white">{drop.title}</div>
                    <div className="text-xs text-gray-400">
                      <span className="mr-2">Views: {drop.impressions.toLocaleString()}</span>
                      <span>Notifies: {drop.notifyCount.toLocaleString()}</span>
                    </div>
                  </div>
                </div>
              </td>
              <td className="px-4 py-3 whitespace-nowrap">
                <div className="text-sm text-white">{drop.brand}</div>
              </td>
              <td className="px-4 py-3 whitespace-nowrap">
                <div className="flex flex-col">
                  <div className="text-sm font-medium text-white">
                    {drop[metricColumn].toLocaleString()}
                  </div>
                  {metricColumn === 'trendScore' && (
                    <div className="text-xs text-gray-400">
                      <span>Shop Clicks: {drop.shopLinkClicks.toLocaleString()}</span>
                    </div>
                  )}
                  {metricColumn === 'notifyCount' && (
                    <div className="text-xs text-gray-400">
                      <span>Trend Score: {drop.trendScore.toLocaleString()}</span>
                    </div>
                  )}
                </div>
              </td>
              <td className="px-4 py-3 whitespace-nowrap">
                <div className="text-sm text-gray-300">
                  {drop.dropDate.toLocaleDateString()}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DropTable; 