import React, { useState, useEffect } from 'react';
import { collection, query, getDocs, getFirestore, Timestamp } from 'firebase/firestore';
import BrandTable from './components/BrandTable';

const BrandMetrics = ({ timeFrame }) => {
  const [metrics, setMetrics] = useState({
    totalBrands: 0,
    totalBrandsWithActiveProducts: 0,
    currentlyActiveBrands: 0,
    activeBrandsPast30d: 0
  });
  const [brandsByEngagement, setBrandsByEngagement] = useState([]);
  const [brandsByShopClicks, setBrandsByShopClicks] = useState([]);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const db = getFirestore();
        
        // Strategy: 
        // 1. Fetch all collections first (brands, products, drops)
        // 2. Process the data in memory to calculate metrics
        // 3. This avoids complex Firebase queries that might not work as expected
        
        console.log("Fetching all brands...");
        const brandsSnapshot = await getDocs(collection(db, 'brands'));
        const brands = [];
        brandsSnapshot.forEach(doc => {
          const data = doc.data();
          brands.push({
            id: doc.id,
            ...data
          });
        });
        console.log(`Fetched ${brands.length} brands`);
        
        console.log("Fetching all products...");
        const productsSnapshot = await getDocs(collection(db, 'allProducts'));
        const products = [];
        productsSnapshot.forEach(doc => {
          const data = doc.data();
          products.push({
            id: doc.id,
            ...data
          });
        });
        console.log(`Fetched ${products.length} products`);
        
        console.log("Fetching all drops...");
        const dropsSnapshot = await getDocs(collection(db, 'drops'));
        const drops = [];
        dropsSnapshot.forEach(doc => {
          const data = doc.data();
          drops.push({
            id: doc.id,
            ...data
          });
        });
        console.log(`Fetched ${drops.length} drops`);
        
        // 1. Calculate total brands
        const totalBrands = brands.length;
        
        // 2. Calculate brands with active products
        const activeProducts = products.filter(product => product.status === 'ACTIVE');
        console.log(`Found ${activeProducts.length} active products`);
        
        // Create a set of brand IDs with active products
        const brandIdsWithActiveProducts = new Set();
        activeProducts.forEach(product => {
          if (product.brandId) {
            brandIdsWithActiveProducts.add(product.brandId);
          }
        });
        const totalBrandsWithActiveProducts = brandIdsWithActiveProducts.size;
        
        // 3. Calculate currently active brands (with active products or upcoming drops)
        const now = new Date();
        
        // Find upcoming drops
        const upcomingDrops = drops.filter(drop => {
          if (!drop.dropDate) return false;
          
          // Handle different date formats
          let dropDate;
          if (drop.dropDate instanceof Timestamp) {
            dropDate = drop.dropDate.toDate();
          } else if (drop.dropDate.seconds) { // Firebase Timestamp object format
            dropDate = new Date(drop.dropDate.seconds * 1000);
          } else if (drop.dropDate.toDate) { // Firebase Timestamp object with toDate method
            dropDate = drop.dropDate.toDate();
          } else if (typeof drop.dropDate === 'string') {
            dropDate = new Date(drop.dropDate);
          } else {
            console.log("Unknown date format for drop:", drop.id, drop.dropDate);
            return false;
          }
          
          return dropDate > now;
        });
        console.log(`Found ${upcomingDrops.length} upcoming drops`);
        
        // Create a set of brand IDs with upcoming drops
        const brandIdsWithUpcomingDrops = new Set();
        upcomingDrops.forEach(drop => {
          if (drop.brandId) {
            brandIdsWithUpcomingDrops.add(drop.brandId);
          }
        });
        
        // Combine sets (active products or upcoming drops)
        const currentlyActiveBrandIds = new Set([
          ...brandIdsWithActiveProducts,
          ...brandIdsWithUpcomingDrops
        ]);
        const currentlyActiveBrands = currentlyActiveBrandIds.size;
        
        // 4. Calculate brands active in past 30 days
        const thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(now.getDate() - 30);
        
        // Find recent drops (past 30 days)
        const recentDrops = drops.filter(drop => {
          if (!drop.dropDate) return false;
          
          // Handle different date formats
          let dropDate;
          if (drop.dropDate instanceof Timestamp) {
            dropDate = drop.dropDate.toDate();
          } else if (drop.dropDate.seconds) { // Firebase Timestamp object format
            dropDate = new Date(drop.dropDate.seconds * 1000);
          } else if (drop.dropDate.toDate) { // Firebase Timestamp object with toDate method
            dropDate = drop.dropDate.toDate();
          } else if (typeof drop.dropDate === 'string') {
            dropDate = new Date(drop.dropDate);
          } else {
            return false;
          }
          
          return dropDate >= thirtyDaysAgo && dropDate <= now;
        });
        console.log(`Found ${recentDrops.length} recent drops in the last 30 days`);
        
        // Create a set of brand IDs with recent drops
        const brandIdsWithRecentDrops = new Set();
        recentDrops.forEach(drop => {
          if (drop.brandId) {
            brandIdsWithRecentDrops.add(drop.brandId);
          }
        });
        
        // Combine sets (active products or recent drops)
        const activeBrandsPast30dIds = new Set([
          ...brandIdsWithActiveProducts,
          ...brandIdsWithRecentDrops
        ]);
        const activeBrandsPast30d = activeBrandsPast30dIds.size;
        
        // Update metrics state
        setMetrics({
          totalBrands,
          totalBrandsWithActiveProducts,
          currentlyActiveBrands,
          activeBrandsPast30d
        });
        
        // 5. Process brands for display
        // Process all brands and calculate trend score and shop clicks
        const processedBrands = brands.map(brand => {
          const brandEngagement = brand.brandEngagement || { views: 0, clicks: 0, follows: 0 };
          const dropsEngagement = brand.dropsEngagement || { impressions: 0, notifyCount: 0, shopLinkClicks: 0, expandedViewClicks: 0 };
          
          return {
            id: brand.id,
            name: brand.name || brand.brand || brand.brandName || 'Unnamed Brand',
            logo: brand.logo || brand.image || brand.brandProfileImageUrl || null,
            websiteUrl: brand.websiteUrl || '#',
            views: brandEngagement.views || 0,
            clicks: brandEngagement.clicks || 0,
            follows: brand.follows || brandEngagement.follows || 0,
            dropsImpressions: dropsEngagement.impressions || 0,
            dropsShopClicks: dropsEngagement.shopLinkClicks || 0,
            trendScore: brand.trendScore || 0,
            totalShopClicks: (brandEngagement.clicks || 0) + (dropsEngagement.shopLinkClicks || 0)
          };
        });
        
        // Sort by engagement score
        const sortedByEngagement = [...processedBrands].sort((a, b) => b.trendScore - a.trendScore);
        setBrandsByEngagement(sortedByEngagement.slice(0, 10));
        
        // Sort by shop clicks
        const sortedByShopClicks = [...processedBrands].sort((a, b) => b.totalShopClicks - a.totalShopClicks);
        setBrandsByShopClicks(sortedByShopClicks.slice(0, 10));
        
      } catch (error) {
        console.error('Error fetching brand metrics:', error);
      }
      setLoading(false);
    };
    
    fetchData();
  }, [timeFrame]);
  
  return (
    <div>
      <h2 className="text-2xl font-bold mb-6">Brand Metrics</h2>
      
      {/* Total Brands Metrics */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
        <div className="bg-gray-700 rounded-lg p-4">
          <div className="text-xl font-semibold text-avnt-orange">Total Brands</div>
          <div className="text-3xl font-bold">{metrics.totalBrands}</div>
        </div>
        
        <div className="bg-gray-700 rounded-lg p-4">
          <div className="text-xl font-semibold text-avnt-orange">Total Brands With Active Products</div>
          <div className="text-3xl font-bold">{metrics.totalBrandsWithActiveProducts}</div>
          <div className="text-xs text-gray-400">Brands that have at least one active product</div>
        </div>
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
        <div className="bg-gray-700 rounded-lg p-4">
          <div className="text-xl font-semibold text-avnt-orange">Currently Active Brands</div>
          <div className="text-3xl font-bold">{metrics.currentlyActiveBrands}</div>
          <div className="text-xs text-gray-400">Brands with active products or upcoming drops</div>
        </div>
        
        <div className="bg-gray-700 rounded-lg p-4">
          <div className="text-xl font-semibold text-avnt-orange">Active Brands Past 30d</div>
          <div className="text-3xl font-bold">{metrics.activeBrandsPast30d}</div>
          <div className="text-xs text-gray-400">Brands active in the last 30 days</div>
        </div>
      </div>
      
      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-4">Brands Ranked by Engagement</h3>
        <BrandTable 
          brands={brandsByEngagement} 
          loading={loading}
          caption={`Top 10 brands by overall engagement (${timeFrame})`}
          metricColumn="trendScore"
          metricLabel="Engagement Score"
        />
      </div>
      
      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-4">Brands Ranked by Shop Clicks</h3>
        <BrandTable 
          brands={brandsByShopClicks} 
          loading={loading}
          caption={`Top 10 brands by total shop clicks (${timeFrame})`}
          metricColumn="totalShopClicks"
          metricLabel="Total Shop Clicks"
        />
      </div>
    </div>
  );
};

export default BrandMetrics; 