// src/Discover.js
import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, useMap } from "react-leaflet";
import L from "leaflet";
import { FirestoreRepository } from "./firebase/FirebaseRepository";
import "leaflet/dist/leaflet.css";

/**
 * AutoFitBounds either:
 *  - Fits the map to all markers with maxZoom=4
 *  - OR, if markers span most of the globe, skip auto-fit and set a manual zoom
 */
function AutoFitBounds({ positions = [] }) {
  const map = useMap();

  useEffect(() => {
    if (!positions.length) return;

    const bounds = L.latLngBounds(positions);

    // Calculate how large this bounding box is
    const latDiff = bounds.getNorth() - bounds.getSouth();
    const lngDiff = bounds.getEast() - bounds.getWest();

    // If markers are extremely spread out (e.g., across continents),
    // auto-fit would show the entire globe, causing gray borders on wide screens.
    // We'll skip auto-fit and manually pick a zoom so the map is taller than the screen.
    const isGlobal = latDiff > 120 && lngDiff > 300; 
    if (isGlobal) {
      // Force a moderate zoom so you don't see top/bottom gray.
      // Adjust lat/lng and zoom as desired.
      map.setView([20, 0], 3);
    } else {
      // Normal auto-fit with a maximum zoom-out of 4
      map.fitBounds(bounds, {
        padding: [50, 50],
        maxZoom: 4,
      });
    }
  }, [positions, map]);

  return null;
}

/**
 * Returns a custom Leaflet DivIcon with:
 *  - Circular image
 *  - Semi-transparent label with brand/store name
 */
function createCustomIcon(imageUrl, displayName) {
  // Fallback if no image
  const safeImage = imageUrl?.length
    ? imageUrl
    : "https://via.placeholder.com/100?text=No+Image";

  const html = `
    <div class="flex flex-col items-center -mt-10">
      <div class="w-12 h-12 rounded-full overflow-hidden border-2 border-white shadow">
        <img src="${safeImage}" class="w-full h-full object-cover" />
      </div>
      <div class="mt-1 px-2 py-1 bg-white bg-opacity-70 text-black text-xs rounded text-center whitespace-nowrap">
        ${displayName}
      </div>
    </div>
  `;

  return L.divIcon({
    html,
    className: "",
    iconAnchor: [16, 32], // "pin" at bottom center
    popupAnchor: [0, -32],
  });
}

export default function Discover() {
  const [brands, setBrands] = useState([]);
  const [stores, setStores] = useState([]);
  const repository = new FirestoreRepository();

  useEffect(() => {
    async function loadData() {
      try {
        const fetchedBrands = await repository.fetchBrands();
        setBrands(fetchedBrands);
      } catch (err) {
        console.error("Error fetching brands:", err);
      }

      try {
        const fetchedStores = await repository.fetchStores();
        setStores(fetchedStores);
      } catch (err) {
        console.error("Error fetching stores:", err);
      }
    }
    loadData();
  }, []);

  // Combine brands + stores for the map
  const mapItems = [
    ...brands
      .filter((b) => b.location && (b.location.latitude || b.location.longitude))
      .map((b) => ({
        type: "brand",
        name: b.brandName,
        imageUrl: b.brandProfileImageUrl,
        lat: b.location.latitude,
        lng: b.location.longitude,
      })),
    ...stores
      .filter((s) => s.location && (s.location.latitude || s.location.longitude))
      .map((s) => ({
        type: "store",
        name: s.storeName,
        imageUrl: s.storeImageUrl,
        lat: s.location.latitude,
        lng: s.location.longitude,
      })),
  ];

  // Default center near mid-Atlantic
  const defaultCenter = [40, -30];
  const defaultZoom = 3;

  return (
    <div className="min-h-screen bg-black text-white">
      <h1 className="text-3xl font-bold text-center py-4">Discover</h1>

      <div className="relative w-full h-[calc(100vh-120px)]">
        <MapContainer
          center={defaultCenter}
          zoom={defaultZoom}
          style={{ width: "100%", height: "100%" }}
          scrollWheelZoom={true}
          // Constrain panning/zooming to avoid big gray areas
          minZoom={2}
          maxZoom={8}
          maxBounds={[[-85, -180], [85, 180]]}
          maxBoundsViscosity={1.0}
          worldCopyJump={false}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            noWrap={true}
            bounds={[[-85, -180], [85, 180]]}
            attribution='&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
          />

          {/* Conditionally auto-fit to markers */}
          <AutoFitBounds positions={mapItems.map((item) => [item.lat, item.lng])} />

          {mapItems.map((item, idx) => (
            <Marker
              key={idx}
              position={[item.lat, item.lng]}
              icon={createCustomIcon(item.imageUrl, item.name)}
            />
          ))}
        </MapContainer>
      </div>
    </div>
  );
}
