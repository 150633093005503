// src/Releases.js
import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { FirestoreRepository } from "./firebase/FirebaseRepository";

function Releases() {
  const [drops, setDrops] = useState([]);
  const [loading, setLoading] = useState(true);
  const repository = new FirestoreRepository();

  useEffect(() => {
    async function loadDrops() {
      try {
        setLoading(true);
        const allDrops = await repository.fetchDrops();
        const now = new Date();

        // "Upcoming" means now < dropDate + 24h
        const upcoming = allDrops.filter((drop) => {
          const dropDate =
            drop.dropDate instanceof Date
              ? drop.dropDate
              : new Date(drop.dropDate);
          return now < new Date(dropDate.getTime() + 24 * 60 * 60 * 1000);
        });

        // Sort ascending by dropDate
        upcoming.sort((a, b) => a.dropDate - b.dropDate);
        setDrops(upcoming);
      } catch (err) {
        console.error("Failed to load drops:", err);
      } finally {
        setLoading(false);
      }
    }
    loadDrops();
  }, []);

  return (
    <div className="min-h-screen bg-black text-white">
      <h1 className="text-3xl font-bold text-center py-4">Upcoming Releases</h1>

      {/* Center the list and give it a max width on desktop */}
      <div className="max-w-4xl mx-auto px-4">
        {loading ? (
          <div className="text-center py-10">Loading releases...</div>
        ) : drops.length > 0 ? (
          <div className="flex flex-col space-y-4 pb-8">
            {drops.map((drop) => (
              <UpcomingDropCard key={drop.id} drop={drop} />
            ))}
          </div>
        ) : (
          <div className="text-center py-10">No upcoming releases found</div>
        )}
      </div>
    </div>
  );
}

function useCountdown(targetDate) {
  const [now, setNow] = useState(Date.now());
  useEffect(() => {
    const timer = setInterval(() => setNow(Date.now()), 1000);
    return () => clearInterval(timer);
  }, []);
  const distance = targetDate.getTime() - now;
  const totalSec = Math.max(0, Math.floor(distance / 1000));
  const days = Math.floor(totalSec / (3600 * 24));
  const hours = Math.floor((totalSec % (3600 * 24)) / 3600);
  const minutes = Math.floor((totalSec % 3600) / 60);
  const seconds = totalSec % 60;
  return { days, hours, minutes, seconds };
}

function UpcomingDropCard({ drop }) {
  const [imageError, setImageError] = useState(false);
  const fallbackImage = "/avntlogo.png"; // Use our local logo as fallback
  
  // Safely handle date conversion
  const dropDate =
    drop.dropDate instanceof Date ? drop.dropDate : new Date(drop.dropDate);
  const dateStr = format(dropDate, "EEEE, MMMM d");
  const timeStr = format(dropDate, "h:mm aa");

  const now = Date.now();
  const timeUntilDrop = dropDate.getTime() - now;
  const isFutureDrop = timeUntilDrop > 0;
  const dropDatePlus24 = dropDate.getTime() + 24 * 3600 * 1000;
  const isWithin24HoursOfDrop = now >= dropDate.getTime() && now < dropDatePlus24;
  const within30Days =
    timeUntilDrop > 0 && timeUntilDrop <= 30 * 24 * 3600 * 1000;
  const { days, hours, minutes, seconds } = useCountdown(dropDate);

  // Simple image URL logic
  const imageUrl = imageError || (!drop.image && (!drop.images || !drop.images[0]))
    ? fallbackImage
    : drop.image || drop.images[0];

  const handleImageError = (e) => {
    setImageError(true);
  };

  const handleShopNow = () => {
    if (drop.shopLink) {
      window.open(drop.shopLink, "_blank");
    }
  };

  const handleNotifyMe = () => {
    const fallbackUrl =
      "https://apps.apple.com/us/app/avnt-buy-sell-streetwear/id1486420898";
    window.open(fallbackUrl, "_blank");
  };

  return (
    // Increase card width and center on desktop
    <div className="rounded-[15px] shadow-md bg-[#111] w-full px-4 py-4 mx-auto md:max-w-2xl">
      <div className="flex items-start gap-4">
        {/* Increase the image size on desktop */}
        <img
          src={imageUrl}
          alt={drop.title || "Drop Image"}
          className="w-[145px] h-[145px] object-cover rounded-[12px] md:w-[200px] md:h-[200px]"
          onError={handleImageError}
        />
        <div className="flex-1 flex flex-col justify-start">
          <p className="text-[16px] font-medium text-white">{dateStr}</p>
          <p className="text-[16px] font-bold text-white leading-tight">
            {drop.title || "Untitled Drop"}
          </p>
          {drop.brand && (
            <p className="text-[14px] text-gray-400 leading-tight">
              by {drop.brand}
            </p>
          )}
          <p className="text-[14px] text-gray-400 mb-2">{timeStr}</p>

          {isFutureDrop ? (
            <>
              {within30Days && (
                <CountdownRow
                  days={days}
                  hours={hours}
                  minutes={minutes}
                  seconds={seconds}
                />
              )}
              <button
                onClick={handleNotifyMe}
                className="bg-avnt-orange text-black text-[14px] font-bold px-4 py-2 rounded-md mt-2 w-[120px]"
              >
                Notify Me
              </button>
            </>
          ) : isWithin24HoursOfDrop ? (
            <button
              onClick={handleShopNow}
              className="bg-avnt-orange text-black text-[14px] font-bold px-4 py-2 rounded-md mt-2 w-[120px]"
            >
              Shop Now
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
}

function CountdownRow({ days, hours, minutes, seconds }) {
  const showDays = days > 0;
  return (
    <div className="flex items-center gap-2">
      {showDays ? (
        <>
          <CountdownSegment number={days} label="days" />
          <CountdownSegment number={hours} label="hours" />
          <CountdownSegment number={minutes} label="minutes" />
        </>
      ) : (
        <>
          <CountdownSegment number={hours} label="hours" />
          <CountdownSegment number={minutes} label="minutes" />
          <CountdownSegment number={seconds} label="seconds" />
        </>
      )}
    </div>
  );
}

function CountdownSegment({ number, label }) {
  const displayValue = number < 10 ? `0${number}` : number;
  return (
    <div className="flex flex-col items-center">
      <div className="w-[50px] h-[40px] bg-gray-700 rounded-[5px] flex items-center justify-center">
        <span className="text-white font-bold text-[16px]">{displayValue}</span>
      </div>
      <span className="text-[10px] text-white mt-[2px]">{label}</span>
    </div>
  );
}

export default Releases;
