// src/firebase/FirebaseRepository.js

import { db } from "../firebase"; // Ensure your firebase.js is correctly initialized
import {
  collection,
  getDocs,
  getDoc,
  doc,
  query,
  where,
  orderBy,
  startAfter,
  limit,
} from "firebase/firestore";
import {
  parseBrand,
  parseBrandDoc,
  parseProduct,
  parseDrop,
  parseStore,
} from "./FirebaseParser";

export class FirestoreRepository {
  /**
   * Fetch all brands.
   */
  async fetchBrands() {
    try {
      const brandsRef = collection(db, "brands");
      const snapshot = await getDocs(brandsRef);
      return snapshot.docs.map((docSnap) => parseBrand(docSnap));
    } catch (error) {
      console.error("Error fetching brands:", error);
      throw error;
    }
  }

  /**
   * Fetch all drops.
   */
  async fetchDrops() {
    try {
      const dropsRef = collection(db, "drops");
      const snapshot = await getDocs(dropsRef);
      return snapshot.docs.map((docSnap) => parseDrop(docSnap));
    } catch (error) {
      console.error("Error fetching drops:", error);
      throw error;
    }
  }

  /**
   * Fetch all stores.
   */
  async fetchStores() {
    try {
      const storesRef = collection(db, "stores");
      const snapshot = await getDocs(storesRef);
      return snapshot.docs.map((docSnap) => parseStore(docSnap));
    } catch (error) {
      console.error("Error fetching stores:", error);
      throw error;
    }
  }

  /**
   * Fetch a single brand by its ID.
   */
  async fetchBrand(brandId) {
    try {
      const brandDocRef = doc(db, "brands", brandId);
      const brandDoc = await getDoc(brandDocRef);
      if (!brandDoc.exists()) {
        throw new Error("No brand found");
      }
      return parseBrandDoc(brandDoc);
    } catch (error) {
      console.error("Error fetching brand:", error);
      throw error;
    }
  }

  /**
   * Fetch drops for a given brand by its ID.
   */
  async fetchBrandDrops(brandId) {
    try {
      const brandDocRef = doc(db, "brands", brandId);
      const dropsRef = collection(db, "drops");
      const q = query(dropsRef, where("brandRef", "==", brandDocRef));
      const snapshot = await getDocs(q);
      return snapshot.docs.map((docSnap) => parseDrop(docSnap));
    } catch (error) {
      console.error("Error fetching brand drops:", error);
      throw error;
    }
  }

  /**
   * Fetch all active products.
   * Note: The parser now includes new trendscore fields.
   */
  async fetchActiveProducts() {
    try {
      const productsRef = collection(db, "allProducts");
      const q = query(productsRef, where("status", "==", "ACTIVE"));
      const snapshot = await getDocs(q);
      return snapshot.docs.map((docSnap) => parseProduct(docSnap));
    } catch (error) {
      console.error("Error fetching active products:", error);
      throw error;
    }
  }

  /**
   * Fetch products in a paginated fashion.
   * @param {DocumentSnapshot|null} lastDoc - The last document from the previous page, or null for the first page.
   * @param {number} pageSize - Number of products per page.
   */
  async fetchProductsPage(lastDoc = null, pageSize = 20) {
    try {
      const productsRef = collection(db, "allProducts");
      let q;
      if (lastDoc) {
        q = query(
          productsRef,
          where("status", "==", "ACTIVE"),
          orderBy("trendScore", "desc"),
          startAfter(lastDoc),
          limit(pageSize)
        );
      } else {
        q = query(
          productsRef,
          where("status", "==", "ACTIVE"),
          orderBy("trendScore", "desc"),
          limit(pageSize)
        );
      }
      const snapshot = await getDocs(q);
      const products = snapshot.docs.map((docSnap) => parseProduct(docSnap));
      const lastVisible = snapshot.docs[snapshot.docs.length - 1] || null;
      return { products, lastVisible };
    } catch (error) {
      console.error("Error fetching paginated products:", error);
      throw error;
    }
  }
}
