"use strict";

/* eslint-disable max-len */

import React, { useState } from "react";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db } from "./firebase";
import { Link } from "react-router-dom";

function Vote() {
  const [brandId, setBrandId] = useState("");
  const [category, setCategory] = useState("MostInnovativeBrand");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "votes"), {
        brandId,
        category,
        userId: "anonymous", // Replace with auth.currentUser.uid if authenticated
        voteCount: 1,
        createdAt: serverTimestamp(),
      });
      setMessage("Vote submitted successfully!");
      setBrandId("");
    } catch (error) {
      setMessage("Error submitting vote: " + error.message);
    }
  };

  return (
    <div className="min-h-screen bg-black text-white p-4">
      <header className="mb-8">
        <nav className="flex justify-between items-center mb-4">
          <h1 className="text-2xl font-bold">AVNT</h1>
          <div className="space-x-4">
            <Link to="/about" className="text-gray-400 hover:text-white">About</Link>
            <Link to="/history" className="text-gray-400 hover:text-white">History</Link>
            <Link to="/" className="text-gray-400 hover:text-white">Home</Link>
          </div>
        </nav>
        <h1 className="text-2xl font-bold mb-4">Vote for a Brand</h1>
      </header>
      <div className="max-w-md mx-auto bg-gray-900 p-6 rounded-lg shadow-md">
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="brandId" className="block text-sm font-medium text-gray-300">
              Brand ID
            </label>
            <input
              type="text"
              id="brandId"
              value={brandId}
              onChange={(e) => setBrandId(e.target.value)}
              className="mt-1 block w-full p-2 bg-gray-800 text-white rounded border border-gray-700 focus:outline-none focus:border-orange-500"
              placeholder="Enter brand ID (e.g., tUixoFzaU4shkYUgSQOI1)"
              required
            />
          </div>
          <div>
            <label htmlFor="category" className="block text-sm font-medium text-gray-300">
              Category
            </label>
            <select
              id="category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              className="mt-1 block w-full p-2 bg-gray-800 text-white rounded border border-gray-700 focus:outline-none focus:border-orange-500"
            >
              <option value="MostInnovativeBrand">Most Innovative Brand</option>
              <option value="BestDrop">Best Drop</option>
            </select>
          </div>
          <button
            type="submit"
            className="w-full bg-orange-500 text-white p-2 rounded hover:bg-orange-600 focus:outline-none"
          >
            Submit Vote
          </button>
        </form>
        {message && <p className="mt-4 text-center text-gray-400">{message}</p>}
      </div>
    </div>
  );
}

export default Vote;