import React, { useState, useEffect } from 'react';
import { Route, Routes, Link, useLocation, Navigate } from 'react-router-dom';
import UserMetrics from './admin/UserMetrics';
import ProductMetrics from './admin/ProductMetrics';
import DropMetrics from './admin/DropMetrics';
import BrandMetrics from './admin/BrandMetrics';
import TimeMetrics from './admin/TimeMetrics';

const AdminDashboard = () => {
  const location = useLocation();
  const [timeFrame, setTimeFrame] = useState('daily'); // 'daily', 'weekly', 'monthly'
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  
  // Get the admin password from environment variables
  // Fallback to a default value if the environment variable isn't set
  const adminPassword = process.env.REACT_APP_ADMIN_PASSWORD || 'AVADMIN777';

  useEffect(() => {
    // Check if user was previously authenticated in this session
    const adminAuth = sessionStorage.getItem('adminAuth');
    if (adminAuth === 'true') {
      setIsAuthenticated(true);
    }
  }, []);
  
  const handleLogin = (e) => {
    e.preventDefault();
    if (password === adminPassword) {
      setIsAuthenticated(true);
      setError('');
      // Store authentication in session storage
      sessionStorage.setItem('adminAuth', 'true');
    } else {
      setError('Invalid password');
    }
  };
  
  const handleLogout = () => {
    setIsAuthenticated(false);
    sessionStorage.removeItem('adminAuth');
  };
  
  // If not authenticated, show login screen
  if (!isAuthenticated) {
    return (
      <div className="min-h-screen bg-gray-900 text-white flex items-center justify-center">
        <div className="bg-gray-800 p-8 rounded-lg shadow-lg max-w-md w-full">
          <h1 className="text-2xl font-bold mb-6 text-center">AVNT Admin</h1>
          <form onSubmit={handleLogin}>
            <div className="mb-4">
              <label htmlFor="password" className="block text-sm font-medium mb-2">
                Admin Password
              </label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-avnt-orange"
                required
              />
            </div>
            {error && <p className="text-red-500 mb-4">{error}</p>}
            <button
              type="submit"
              className="w-full bg-avnt-orange text-black font-bold py-2 px-4 rounded-md hover:bg-orange-500 transition-colors"
            >
              Login
            </button>
          </form>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      <div className="container mx-auto px-4 py-8">
        <div className="mb-8 flex justify-between items-center">
          <h1 className="text-3xl font-bold">AVNT Admin Dashboard</h1>
          <button 
            onClick={handleLogout}
            className="bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-md"
          >
            Logout
          </button>
        </div>

        {/* Time frame selector */}
        <div className="mb-4 flex items-center">
          <span className="mr-4">Time Frame:</span>
          <div className="flex space-x-2">
            <button 
              className={`px-4 py-2 rounded ${timeFrame === 'daily' ? 'bg-avnt-orange text-black' : 'bg-gray-800'}`}
              onClick={() => setTimeFrame('daily')}
            >
              Daily
            </button>
            <button 
              className={`px-4 py-2 rounded ${timeFrame === 'weekly' ? 'bg-avnt-orange text-black' : 'bg-gray-800'}`}
              onClick={() => setTimeFrame('weekly')}
            >
              Weekly
            </button>
            <button 
              className={`px-4 py-2 rounded ${timeFrame === 'monthly' ? 'bg-avnt-orange text-black' : 'bg-gray-800'}`}
              onClick={() => setTimeFrame('monthly')}
            >
              Monthly
            </button>
          </div>
        </div>

        {/* Navigation tabs */}
        <div className="flex flex-wrap border-b border-gray-700">
          <Link 
            to="/admin" 
            className={`py-3 px-6 ${location.pathname === '/admin' ? 'border-b-2 border-avnt-orange' : ''}`}
          >
            User Activity
          </Link>
          <Link 
            to="/admin/products" 
            className={`py-3 px-6 ${location.pathname === '/admin/products' ? 'border-b-2 border-avnt-orange' : ''}`}
          >
            Products
          </Link>
          <Link 
            to="/admin/drops" 
            className={`py-3 px-6 ${location.pathname === '/admin/drops' ? 'border-b-2 border-avnt-orange' : ''}`}
          >
            Drops
          </Link>
          <Link 
            to="/admin/brands" 
            className={`py-3 px-6 ${location.pathname === '/admin/brands' ? 'border-b-2 border-avnt-orange' : ''}`}
          >
            Brands
          </Link>
          <Link 
            to="/admin/time" 
            className={`py-3 px-6 ${location.pathname === '/admin/time' ? 'border-b-2 border-avnt-orange' : ''}`}
          >
            Time on App
          </Link>
        </div>

        {/* Content area */}
        <div className="bg-gray-800 rounded-lg p-6">
          <Routes>
            <Route path="" element={<UserMetrics timeFrame={timeFrame} />} />
            <Route path="products" element={<ProductMetrics timeFrame={timeFrame} />} />
            <Route path="drops" element={<DropMetrics timeFrame={timeFrame} />} />
            <Route path="brands" element={<BrandMetrics timeFrame={timeFrame} />} />
            <Route path="time" element={<TimeMetrics timeFrame={timeFrame} />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard; 