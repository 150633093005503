// src/About.js
import React from "react";

function About() {
  return (
    <div className="min-h-screen bg-black text-white p-4">
      <header className="text-center mb-8">
        <h1 className="text-3xl font-bold mb-4">About AVNT</h1>
      </header>
      <section className="max-w-3xl mx-auto">
        <p className="text-lg mb-4">
          AVNT is your ultimate fashion source.
        </p>
        <p className="text-lg mb-4">
          Our mission is to deliver you the most exclusive brands, people, and products daily.
        </p>
        <p className="text-lg mb-4">
          We do this through the AVNT website and app, giving you a central, reliable platform to stay up to date and shop the most exclusive brands, drops, and products.
        </p>
        <p className="text-lg mb-4">
          Our broader mission is to creatively enable the lives of billions of people around the world.
        </p>
        <p className="text-lg mb-4">
          We aim to do this by providing the platform, tools, and resources necessary for builders to thrive.
        </p>
        <p className="text-lg mb-4 font-bold">Our current platforms include:</p>
        <ul className="list-disc list-inside mb-4 text-gray-300">
          <li>
            <strong>AVNT</strong> — our consumer media and tech platform. This serves to deliver the exclusive brands, people, and products daily to our community of hundreds of thousands of consumers.
          </li>
          <li>
            <strong>SCALE</strong> — our marketing division that provides top-tier digital marketing solutions to help brands scale.
          </li>
          <li>
            <strong>LABS</strong> — our creative division that builds and launches products that we want to see in the market.
          </li>
          <li>
            <strong>MUSIC</strong> — COMING SOON, your ultimate music source.
          </li>
          <li>
            <strong>VAULT</strong> — COMING SOON, pioneering virtual fashion.
          </li>
        </ul>
        <p className="text-lg mb-4">
          Check them out and let us know if you have any questions or ideas.
        </p>
        <p className="text-lg mb-4">
          If you want to be part of our team building the future of fashion, email <a href="mailto:careers@avnt.space" className="text-orange-500 hover:text-orange-400">careers@avnt.space</a> with something amazing you've built.
        </p>
        <p className="text-lg">
          It's only the beginning.
        </p>
      </section>
    </div>
  );
}

export default About;