import React from "react";

function PrivacyPolicy() {
  return (
    <div className="min-h-screen bg-black text-white p-4">
      <header className="text-center mb-8">
        <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
        <p className="text-gray-400">Last updated: {new Date().toLocaleDateString()}</p>
      </header>
      
      <section className="max-w-3xl mx-auto space-y-6">
        <div>
          <h2 className="text-xl font-bold mb-2 text-orange-500">1. Introduction</h2>
          <p className="mb-2">
            AVNT, Inc. ("AVNT," "we," "us," or "our") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website, mobile application, and use our services.
          </p>
          <p>
            By accessing or using our services, you consent to the collection, use, disclosure, and storage of your information as described in this Privacy Policy. If you do not agree with our policies and practices, please do not use our services. This Privacy Policy constitutes a legally binding agreement between you and AVNT, Inc.
          </p>
        </div>

        <div>
          <h2 className="text-xl font-bold mb-2 text-orange-500">2. Information We Collect</h2>
          <h3 className="text-lg font-semibold mb-1 text-gray-300">2.1 Personal Information</h3>
          <p className="mb-2">
            We may collect personal information that you voluntarily provide to us when you:
          </p>
          <ul className="list-disc list-inside mb-4 text-gray-300 space-y-1">
            <li>Create an account or profile</li>
            <li>Make a purchase</li>
            <li>Sign up for our newsletter</li>
            <li>Participate in promotions, contests, or surveys</li>
            <li>Contact our customer service</li>
            <li>Post comments or reviews</li>
          </ul>
          <p className="mb-2">
            This information may include:
          </p>
          <ul className="list-disc list-inside mb-4 text-gray-300 space-y-1">
            <li>Contact information (name, email address, phone number, shipping address)</li>
            <li>Payment information (credit card details, billing address)</li>
            <li>Account credentials (username, password)</li>
            <li>Profile information (profile picture, preferences)</li>
            <li>Communication content (messages, feedback)</li>
          </ul>

          <h3 className="text-lg font-semibold mb-1 text-gray-300">2.2 Automatically Collected Information</h3>
          <p className="mb-2">
            When you access or use our services, we automatically collect certain information about your device and usage, including:
          </p>
          <ul className="list-disc list-inside mb-4 text-gray-300 space-y-1">
            <li>Device information (IP address, device type, operating system, browser type)</li>
            <li>Usage data (pages visited, time spent, referring/exit pages, date/time stamps)</li>
            <li>Location information (if location services are enabled)</li>
            <li>Cookies and similar tracking technologies</li>
          </ul>
        </div>

        <div>
          <h2 className="text-xl font-bold mb-2 text-orange-500">3. How We Use Your Information</h2>
          <p className="mb-2">
            We may use the information we collect for various purposes, including to:
          </p>
          <ul className="list-disc list-inside mb-4 text-gray-300 space-y-1">
            <li>Provide, maintain, and improve our services</li>
            <li>Process and fulfill your orders and transactions</li>
            <li>Create and manage your account</li>
            <li>Send you order confirmations, updates, and support messages</li>
            <li>Send you marketing communications (with your consent)</li>
            <li>Personalize your experience and deliver tailored content</li>
            <li>Analyze usage patterns and trends to improve our website and services</li>
            <li>Detect, prevent, and address technical issues, fraud, or illegal activities</li>
            <li>Enforce our Terms of Service and other policies</li>
            <li>Comply with legal obligations</li>
          </ul>
        </div>

        <div>
          <h2 className="text-xl font-bold mb-2 text-orange-500">4. How We Share Your Information</h2>
          <p className="mb-2">
            We may share your information with the following categories of third parties:
          </p>
          <ul className="list-disc list-inside mb-4 text-gray-300 space-y-1">
            <li><strong>Service Providers:</strong> We may share your information with third-party vendors, service providers, contractors, or agents who perform services for us or on our behalf, such as payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance. These entities are required to use your information solely as directed by us and in a manner consistent with this Privacy Policy.</li>
            <li><strong>Business Partners:</strong> We may share your information with our business partners to offer you certain products, services, or promotions.</li>
            <li><strong>Affiliates:</strong> We may share your information with our affiliates, in which case we will require those affiliates to honor this Privacy Policy.</li>
            <li><strong>Legal Requirements:</strong> We may disclose your information where required to do so by law or in response to valid requests by public authorities (e.g., a court or a government agency), to enforce our Terms of Service, to protect and defend the rights or property of AVNT, Inc., or to protect the personal safety of users of the Services or the public.</li>
            <li><strong>Business Transfers:</strong> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company. In such an event, we will make reasonable efforts to provide notice before your information is transferred or becomes subject to a different privacy policy.</li>
            <li><strong>With Your Consent:</strong> We may disclose your information for any other purpose with your consent.</li>
            <li><strong>Aggregated or De-identified Data:</strong> We may share aggregated or de-identified information, which cannot reasonably be used to identify you, with third parties for marketing, advertising, research, or similar purposes.</li>
          </ul>
        </div>

        <div>
          <h2 className="text-xl font-bold mb-2 text-orange-500">5. Cookies and Tracking Technologies</h2>
          <p className="mb-2">
            We use cookies and similar tracking technologies to track activity on our services and store certain information. Cookies are files with a small amount of data that may include an anonymous unique identifier.
          </p>
          <p className="mb-2">
            We use cookies for the following purposes:
          </p>
          <ul className="list-disc list-inside mb-4 text-gray-300 space-y-1">
            <li><strong>Essential cookies:</strong> Necessary for the operation of our services</li>
            <li><strong>Analytical/performance cookies:</strong> Allow us to recognize and count the number of visitors and see how visitors move around our services</li>
            <li><strong>Functionality cookies:</strong> Enable us to personalize content for you</li>
            <li><strong>Targeting cookies:</strong> Record your visit to our services, the pages you have visited, and the links you have followed</li>
          </ul>
          <p className="mb-2">
            You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our services.
          </p>
        </div>

        <div>
          <h2 className="text-xl font-bold mb-2 text-orange-500">6. Third-Party Analytics</h2>
          <p className="mb-2">
            We use third-party analytics services, such as Google Analytics, to help us understand how users engage with our services. These services may collect information about your online activities over time and across different websites and online services.
          </p>
          <p>
            You can opt out of Google Analytics by installing the Google Analytics opt-out browser add-on available at https://tools.google.com/dlpage/gaoptout.
          </p>
        </div>

        <div>
          <h2 className="text-xl font-bold mb-2 text-orange-500">7. Data Security</h2>
          <p className="mb-2">
            We implement reasonable technical and organizational measures to protect the security of your personal information. However, please be aware that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee the absolute security of your personal information.
          </p>
          <p className="mb-2">
            You are responsible for maintaining the confidentiality of your account credentials and for restricting access to your device. If you become aware of any unauthorized access to your account, please contact us immediately.
          </p>
          <p>
            AVNT, Inc. is not responsible for circumvention of any privacy settings or security measures contained on the Services or third-party websites. While we strive to use commercially acceptable means to protect your personal information, we cannot ensure or warrant the security of any information you transmit to us.
          </p>
        </div>

        <div>
          <h2 className="text-xl font-bold mb-2 text-orange-500">8. Data Retention</h2>
          <p>
            We will retain your personal information only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your information to the extent necessary to comply with our legal obligations, resolve disputes, and enforce our policies.
          </p>
        </div>

        <div>
          <h2 className="text-xl font-bold mb-2 text-orange-500">9. Children's Privacy</h2>
          <p>
            Our services are not intended for children under the age of 13. We do not knowingly collect personally identifiable information from children under 13. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us. If we discover that a child under 13 has provided us with personal information, we will delete such information from our servers.
          </p>
        </div>

        <div>
          <h2 className="text-xl font-bold mb-2 text-orange-500">10. Your Privacy Rights</h2>
          <h3 className="text-lg font-semibold mb-1 text-gray-300">10.1 Account Information</h3>
          <p className="mb-2">
            You can review and change your personal information by logging into your account and visiting your account profile page.
          </p>

          <h3 className="text-lg font-semibold mb-1 text-gray-300">10.2 Marketing Communications</h3>
          <p className="mb-2">
            You can opt out of receiving marketing emails from us by following the unsubscribe instructions provided in the emails. Please note that we may still send you transactional or administrative emails, such as order confirmations and account-related messages.
          </p>

          <h3 className="text-lg font-semibold mb-1 text-gray-300">10.3 California Residents</h3>
          <p className="mb-2">
            If you are a California resident, you have certain rights under the California Consumer Privacy Act (CCPA), including:
          </p>
          <ul className="list-disc list-inside mb-4 text-gray-300 space-y-1">
            <li>The right to know what personal information we collect about you</li>
            <li>The right to request deletion of your personal information</li>
            <li>The right to opt out of the sale of your personal information</li>
            <li>The right to non-discrimination for exercising your CCPA rights</li>
          </ul>
          <p className="mb-2">
            To exercise these rights, please contact us using the information provided in the "Contact Us" section.
          </p>

          <h3 className="text-lg font-semibold mb-1 text-gray-300">10.4 European Economic Area (EEA) Residents</h3>
          <p className="mb-2">
            If you are located in the EEA, you have certain rights under the General Data Protection Regulation (GDPR), including:
          </p>
          <ul className="list-disc list-inside mb-4 text-gray-300 space-y-1">
            <li>The right to access your personal data</li>
            <li>The right to rectification of inaccurate personal data</li>
            <li>The right to erasure of your personal data</li>
            <li>The right to restriction of processing of your personal data</li>
            <li>The right to data portability</li>
            <li>The right to object to processing of your personal data</li>
          </ul>
          <p className="mb-2">
            To exercise these rights, please contact us using the information provided in the "Contact Us" section.
          </p>
        </div>

        <div>
          <h2 className="text-xl font-bold mb-2 text-orange-500">11. International Data Transfers</h2>
          <p className="mb-2">
            Your information may be transferred to, and maintained on, computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ from those in your jurisdiction. By providing your information, you consent to this transfer.
          </p>
          <p>
            If you are located in the European Economic Area (EEA), United Kingdom, or Switzerland, please note that we may transfer your personal information to countries outside these regions, including the United States, which may not have data protection laws considered to be as comprehensive as those in your jurisdiction. Whenever we transfer your personal information out of the EEA, UK, or Switzerland, we will take appropriate safeguards to ensure that your personal information remains protected. AVNT, Inc. complies with applicable data protection laws when transferring your personal information across international borders.
          </p>
        </div>

        <div>
          <h2 className="text-xl font-bold mb-2 text-orange-500">12. Changes to This Privacy Policy</h2>
          <p className="mb-2">
            We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date at the top of this Privacy Policy. You are advised to review this Privacy Policy periodically for any changes.
          </p>
          <p>
            If we make material changes to this Privacy Policy, we will make reasonable efforts to notify you either through the email address you have provided to us or by placing a prominent notice on our website. Changes to this Privacy Policy are effective when they are posted on this page. Your continued use of the Services following the posting of changes constitutes your acceptance of such changes.
          </p>
        </div>

        <div>
          <h2 className="text-xl font-bold mb-2 text-orange-500">13. Do Not Track Signals</h2>
          <p>
            Some browsers feature a "Do Not Track" (DNT) setting that requests that websites not track your browsing activities. We currently do not respond to DNT signals. However, you can generally opt out of receiving personalized ads from third-party advertisers and ad networks who are members of the Network Advertising Initiative (NAI) or who follow the Digital Advertising Alliance's Self-Regulatory Principles for Online Behavioral Advertising by visiting the opt-out pages on their websites.
          </p>
        </div>

        <div>
          <h2 className="text-xl font-bold mb-2 text-orange-500">14. Contact Us</h2>
          <p className="mb-2">
            If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:privacy@avnt.space" className="text-orange-500 hover:text-orange-400">privacy@avnt.space</a>.
          </p>
          <p>
            AVNT, Inc.<br />
            <br />
            Email: privacy@avnt.space
          </p>
        </div>

        <div>
          <h2 className="text-xl font-bold mb-2 text-orange-500">15. Severability</h2>
          <p>
            If any provision of this Privacy Policy is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that this Privacy Policy will otherwise remain in full force and effect and enforceable.
          </p>
        </div>
      </section>
    </div>
  );
}

export default PrivacyPolicy; 