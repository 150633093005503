import React, { useState, useEffect } from 'react';
import { collection, query, where, orderBy, limit, getDocs, getFirestore, doc, getDoc, Timestamp } from 'firebase/firestore';
import MetricCard from './components/MetricCard';
import UserTable from './components/UserTable';

const UserMetrics = ({ timeFrame }) => {
  const [uniqueUsers, setUniqueUsers] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [newUsers24h, setNewUsers24h] = useState(0);
  const [newUsers7d, setNewUsers7d] = useState(0);
  const [newUsers30d, setNewUsers30d] = useState(0);
  const [topUsersByActivity, setTopUsersByActivity] = useState([]);
  const [topUsersByShopClicks, setTopUsersByShopClicks] = useState([]);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const db = getFirestore();
        
        // First try to load pre-aggregated data
        const statsRef = doc(db, 'adminStats', 'userMetrics');
        const statsDoc = await getDoc(statsRef);
        
        if (statsDoc.exists()) {
          const statsData = statsDoc.data();
          
          // Get unique users count based on timeFrame
          if (timeFrame === 'daily') {
            setUniqueUsers(statsData.uniqueUsers?.daily || 0);
          } else if (timeFrame === 'weekly') {
            setUniqueUsers(statsData.uniqueUsers?.weekly || 0);
          } else {
            setUniqueUsers(statsData.uniqueUsers?.monthly || 0);
          }
          
          // Set total and new user counts if available in aggregated data
          setTotalUsers(statsData.totalUsers || 0);
          setNewUsers24h(statsData.newUsers?.last24h || 0);
          setNewUsers7d(statsData.newUsers?.last7d || 0);
          setNewUsers30d(statsData.newUsers?.last30d || 0);
          
          // Get user data and fetch additional info
          const engagementData = statsData.topUsers?.byEngagement || [];
          const shopClicksData = statsData.topUsers?.byShopClicks || [];
          
          // Fetch user profile info for display
          const userIds = new Set([
            ...engagementData.slice(0, 10).map(u => u.userId),
            ...shopClicksData.slice(0, 10).map(u => u.userId)
          ]);
          
          const userProfiles = {};
          const userPromises = Array.from(userIds).map(async (userId) => {
            const userDoc = await getDoc(doc(db, 'users', userId));
            if (userDoc.exists()) {
              const userData = userDoc.data();
              userProfiles[userId] = {
                username: userData.username || 'Anonymous',
                email: userData.email || 'N/A',
                lastActive: userData.lastActive
              };
            }
            return userId;
          });
          
          await Promise.all(userPromises);
          
          // Map engagement data to display format
          const topActivity = engagementData.slice(0, 10).map(user => ({
            id: user.userId,
            username: userProfiles[user.userId]?.username || 'Anonymous',
            email: userProfiles[user.userId]?.email || 'N/A',
            engagementScore: user.totalActivity || 0,
            lastActive: userProfiles[user.userId]?.lastActive?.toDate() || new Date(),
            totalShopClicks: user.shopClicks || 0
          }));
          
          // Map shop clicks data to display format
          const topShoppers = shopClicksData.slice(0, 10).map(user => ({
            id: user.userId,
            username: userProfiles[user.userId]?.username || 'Anonymous',
            email: userProfiles[user.userId]?.email || 'N/A',
            engagementScore: user.totalActivity || 0,
            lastActive: userProfiles[user.userId]?.lastActive?.toDate() || new Date(),
            totalShopClicks: user.shopClicks || 0
          }));
          
          setTopUsersByActivity(topActivity);
          setTopUsersByShopClicks(topShoppers);
        } else {
          // Fallback to direct query if no aggregated data
          console.log('No aggregated user metrics found, falling back to direct query');
          
          // Calculate date range based on timeFrame
          const now = new Date();
          let startDate = new Date();
          
          if (timeFrame === 'daily') {
            startDate.setDate(now.getDate() - 1);
          } else if (timeFrame === 'weekly') {
            startDate.setDate(now.getDate() - 7);
          } else if (timeFrame === 'monthly') {
            startDate.setMonth(now.getMonth() - 1);
          }
          
          // Get unique users count - Note: This could be slow with large datasets
          const sessionsRef = collection(db, 'userSessions');
          const sessionQuery = query(
            sessionsRef,
            where('timestamp', '>=', startDate)
          );
          
          const sessionDocs = await getDocs(sessionQuery);
          const uniqueUserIds = new Set();
          
          sessionDocs.forEach(doc => {
            const userData = doc.data();
            if (userData.userId) {
              uniqueUserIds.add(userData.userId);
            }
          });
          
          setUniqueUsers(uniqueUserIds.size);
          
          // Get total users count
          const usersRef = collection(db, 'users');
          const totalUsersSnapshot = await getDocs(usersRef);
          setTotalUsers(totalUsersSnapshot.size);
          
          // Calculate timestamps for new user metrics
          const timestamp24h = Timestamp.fromDate(new Date(now.getTime() - 24 * 60 * 60 * 1000));
          const timestamp7d = Timestamp.fromDate(new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000));
          const timestamp30d = Timestamp.fromDate(new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000));
          
          // Get new users in last 24 hours
          const newUsers24hQuery = query(
            usersRef,
            where('createdAt', '>=', timestamp24h)
          );
          const newUsers24hSnapshot = await getDocs(newUsers24hQuery);
          setNewUsers24h(newUsers24hSnapshot.size);
          
          // Get new users in last 7 days
          const newUsers7dQuery = query(
            usersRef,
            where('createdAt', '>=', timestamp7d)
          );
          const newUsers7dSnapshot = await getDocs(newUsers7dQuery);
          setNewUsers7d(newUsers7dSnapshot.size);
          
          // Get new users in last 30 days
          const newUsers30dQuery = query(
            usersRef,
            where('createdAt', '>=', timestamp30d)
          );
          const newUsers30dSnapshot = await getDocs(newUsers30dQuery);
          setNewUsers30d(newUsers30dSnapshot.size);
          
          // Get top users by activity
          const usersQuery = query(
            usersRef,
            orderBy('engagementScore', 'desc'),
            limit(10)
          );
          
          const userDocs = await getDocs(usersQuery);
          const topUsersData = [];
          
          userDocs.forEach(doc => {
            const userData = doc.data();
            topUsersData.push({
              id: doc.id,
              username: userData.username || 'Anonymous',
              email: userData.email || 'N/A',
              engagementScore: userData.engagementScore || 0,
              lastActive: userData.lastActive ? userData.lastActive.toDate() : new Date(),
              totalShopClicks: userData.totalShopClicks || 0
            });
          });
          
          setTopUsersByActivity(topUsersData);
          setTopUsersByShopClicks([...topUsersData].sort((a, b) => b.totalShopClicks - a.totalShopClicks));
        }
      } catch (error) {
        console.error('Error fetching user metrics:', error);
      }
      setLoading(false);
    };
    
    fetchData();
  }, [timeFrame]);
  
  return (
    <div>
      <h2 className="text-2xl font-bold mb-6">User Metrics</h2>
      
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-6 mb-8">
        <MetricCard 
          title="Unique Users" 
          value={loading ? '...' : uniqueUsers.toLocaleString()} 
          description={`${timeFrame === 'daily' ? 'Today' : timeFrame === 'weekly' ? 'This week' : 'This month'}`}
          icon="👤"
        />
        <MetricCard 
          title="Total User Accounts" 
          value={loading ? '...' : totalUsers.toLocaleString()} 
          description="All time"
          icon="👥"
        />
        <MetricCard 
          title="New Users (24h)" 
          value={loading ? '...' : newUsers24h.toLocaleString()} 
          description="Last 24 hours"
          icon="✨"
        />
        <MetricCard 
          title="New Users (7d)" 
          value={loading ? '...' : newUsers7d.toLocaleString()} 
          description="Last 7 days"
          icon="📈"
        />
        <MetricCard 
          title="New Users (30d)" 
          value={loading ? '...' : newUsers30d.toLocaleString()} 
          description="Last 30 days"
          icon="📆"
        />
      </div>
      
      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-4">Top 10 Users by Activity</h3>
        <UserTable 
          users={topUsersByActivity} 
          loading={loading}
          caption={`Top users ranked by engagement score (${timeFrame})`}
        />
      </div>
      
      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-4">Top 10 Shoppers</h3>
        <UserTable 
          users={topUsersByShopClicks} 
          loading={loading}
          caption={`Users ranked by number of shop clicks (${timeFrame})`}
          shopColumn={true}
        />
      </div>
    </div>
  );
};

export default UserMetrics; 