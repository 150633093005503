// src/Home.js
import React, { useEffect, useState } from "react";
import { FirestoreRepository } from "./firebase/FirebaseRepository";
import avntLogoWhite from "./assets/images/avnt_logo_white.PNG"; // Ensure correct path

function Home() {
  const [categorySections, setCategorySections] = useState([]);
  const repository = new FirestoreRepository();

  useEffect(() => {
    async function loadProducts() {
      try {
        // Fetch active products and group/sort them by runningTrendScore24h
        const products = await repository.fetchActiveProducts();
        const grouped = groupAndSortByCategory(products);
        setCategorySections(grouped);
      } catch (err) {
        console.error("Failed to load products:", err);
      }
    }
    loadProducts();
  }, []);

  return (
    <div className="min-h-screen bg-black text-white">
      {/* Hero / Heading Section */}
      <header className="p-4 text-center">
        <img
          src={avntLogoWhite}
          alt="AVNT Logo"
          className="mx-auto mb-2 h-40" // Adjust size as desired
        />
        <p className="text-2xl font-bold text-white mb-2">
          Your Ultimate Fashion Source
        </p>
        <p className="text-lg text-white mb-6">
          Delivering you the most exclusive brands, people, and products daily
        </p>
        <a
          href="https://apps.apple.com/us/app/avnt-buy-sell-streetwear/id1486420898"
          target="_blank"
          rel="noopener noreferrer"
          className="bg-avnt-orange text-black px-6 py-3 rounded-full hover:bg-avnt-orange inline-block"
        >
          Login to Explore
        </a>
      </header>

      {/* Render each category's "Top 10" section */}
      <div className="bg-black">
        {categorySections.map((section) => (
          <CategorySection key={section.id} section={section} />
        ))}
      </div>
    </div>
  );
}

/**
 * Groups products by category, sorts each group descending by runningTrendScore24h,
 * and returns exactly 10 products for each category that has at least 10 products.
 * Categories with fewer than 10 products are excluded.
 */
function groupAndSortByCategory(products) {
  const map = {};
  for (const p of products) {
    if (!p.category) continue;
    if (!map[p.category]) {
      map[p.category] = [];
    }
    map[p.category].push(p);
  }
  
  const sections = Object.keys(map)
    .filter(cat => map[cat].length >= 10) // Only include categories with at least 10 products
    .map((cat) => {
      const sorted = map[cat].sort(
        (a, b) => (b.runningTrendScore24h || 0) - (a.runningTrendScore24h || 0)
      );
      const top10 = sorted.slice(0, 10); // Get exactly 10 products
      return { id: `cat-${cat}`, category: cat, products: top10 };
    });
    
  sections.sort((a, b) => a.category.localeCompare(b.category));
  return sections;
}

/**
 * CategorySection component renders a section title and a horizontal scroll of products.
 */
function CategorySection({ section }) {
  const { category, products } = section;
  const title = `Top 10 in ${category} Today`;
  return (
    <div className="mb-0">
      <h2 className="text-xl font-bold pt-[13px] pl-5">{title}</h2>
      {/* Add "no-scrollbar" class to hide horizontal scroll bar */}
      <div className="overflow-x-auto py-2 no-scrollbar">
        <div className="flex space-x-[30px] px-5">
          {products.map((product, index) => (
            <ProductCard key={product.id} product={product} rank={index + 1} />
          ))}
        </div>
      </div>
    </div>
  );
}

/**
 * ProductCard renders the product image and title.
 * Clicking the card opens the product's shopLink in a new tab.
 */
function ProductCard({ product, rank }) {
  const imageUrl =
    product.images?.[0] ||
    "https://via.placeholder.com/300x400?text=No+Image";
  const title = product.title;

  const handleClick = () => {
    if (product.shopLink) {
      window.open(product.shopLink, "_blank");
    }
  };

  return (
    <div
      onClick={handleClick}
      className="relative bg-transparent cursor-pointer"
      style={{ width: "170px", height: "230px" }}
    >
      <div className="absolute top-0" style={{ transform: "translateX(-20px)" }}>
        <OutlinedRank rank={rank} />
      </div>
      <div
        className="relative bg-transparent"
        style={{ transform: "translateX(30px)", width: "130px" }}
      >
        <img
          src={imageUrl}
          alt={title}
          style={{
            height: "180px",
            objectFit: "cover",
            borderRadius: "10px",
            width: "100%",
          }}
        />
        <p
          className="text-center text-white font-bold mt-2"
          style={{ fontSize: "13.5px", lineHeight: "1.2" }}
        >
          {title}
        </p>
      </div>
    </div>
  );
}

function OutlinedRank({ rank }) {
  const rankStr = `${rank}`;
  return (
    <div className="relative" style={{ width: "120px", height: "120px" }}>
      <span
        className="absolute text-[120px] font-bold text-white leading-none"
        style={{ transform: "translate(-2px, 0)" }}
      >
        {rankStr}
      </span>
      <span
        className="absolute text-[120px] font-bold text-white leading-none"
        style={{ transform: "translate(2px, 0)" }}
      >
        {rankStr}
      </span>
      <span
        className="absolute text-[120px] font-bold text-white leading-none"
        style={{ transform: "translate(0, -2px)" }}
      >
        {rankStr}
      </span>
      <span
        className="absolute text-[120px] font-bold text-white leading-none"
        style={{ transform: "translate(0, 2px)" }}
      >
        {rankStr}
      </span>
      <span className="absolute text-[120px] font-bold text-black leading-none">
        {rankStr}
      </span>
    </div>
  );
}

export default Home;
