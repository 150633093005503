"use strict";

import { useEffect, useState } from "react";
import { collection, onSnapshot, query, orderBy } from "firebase/firestore";
import { db } from "./firebase";
import { Link } from "react-router-dom";

function Brands() {
  const [brands, setBrands] = useState([]);
  const [search, setSearch] = useState("");

  // Fetch all brands ordered alphabetically by brandName.
  useEffect(() => {
    const q = query(collection(db, "brands"), orderBy("brandName", "asc"));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const brandData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setBrands(brandData);
    });
    return () => unsubscribe();
  }, []);

  // Filter brands based on the search query.
  const filteredBrands = brands.filter((brand) =>
    brand.brandName.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div className="min-h-screen bg-black text-white p-4">
      <header className="mb-8">
        {/* Search Bar Only */}
        <div className="mb-4">
          <input
            type="text"
            placeholder="Search brands"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="w-full p-2 rounded bg-gray-800 text-white"
          />
        </div>
      </header>
      {/* Brands Grid */}
      <div className="grid grid-cols-3 gap-4 sm:grid-cols-4 md:grid-cols-5">
        {filteredBrands.map((brand) => (
          <Link
            key={brand.id}
            to={`/brand/${brand.id}`}
            className="flex flex-col items-center"
          >
            {brand.brandProfileImageUrl ? (
              <img
                src={brand.brandProfileImageUrl}
                alt={brand.brandName}
                className="w-20 h-20 rounded-full object-cover mb-2"
              />
            ) : (
              <div className="w-20 h-20 rounded-full bg-gray-700 flex items-center justify-center text-white">
                {brand.brandName.charAt(0)}
              </div>
            )}
            <p className="text-sm">{brand.brandName}</p>
          </Link>
        ))}
      </div>
      {filteredBrands.length === 0 && (
        <p className="text-gray-400 text-center mt-4">No brands found.</p>
      )}
    </div>
  );
}

export default Brands;
