import React, { useState, useEffect } from 'react';
import { collection, getDocs, getFirestore, Timestamp } from 'firebase/firestore';
import DropTable from './components/DropTable';

const DropMetrics = ({ timeFrame }) => {
  const [totalUpcomingDrops, setTotalUpcomingDrops] = useState(0);
  const [engagedDrops, setEngagedDrops] = useState([]);
  const [notifiedDrops, setNotifiedDrops] = useState([]);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const db = getFirestore();
        
        // First principles approach: 
        // 1. Fetch ALL drops & brands at once
        // 2. Process everything in memory
        // 3. No complex Firebase queries
        
        console.log("Fetching all drops...");
        const dropsSnapshot = await getDocs(collection(db, 'drops'));
        const drops = [];
        dropsSnapshot.forEach(doc => {
          const data = doc.data();
          drops.push({
            id: doc.id,
            ...data
          });
        });
        console.log(`Fetched ${drops.length} drops`);
        
        // Get all brands to look up names if needed
        console.log("Fetching all brands...");
        const brandsSnapshot = await getDocs(collection(db, 'brands'));
        const brandsMap = new Map();
        brandsSnapshot.forEach(doc => {
          const brandData = doc.data();
          brandsMap.set(doc.id, {
            id: doc.id,
            name: brandData.name || brandData.brand || brandData.brandName || 'Unknown Brand',
            ...brandData
          });
        });
        console.log(`Fetched ${brandsMap.size} brands`);
        
        // 1. Calculate total upcoming drops
        const now = new Date();
        const upcomingDrops = drops.filter(drop => {
          if (!drop.dropDate) return false;
          
          // Handle different date formats
          let dropDate;
          try {
            if (drop.dropDate instanceof Timestamp) {
              dropDate = drop.dropDate.toDate();
            } else if (drop.dropDate.seconds) { // Firebase Timestamp object format
              dropDate = new Date(drop.dropDate.seconds * 1000);
            } else if (drop.dropDate.toDate) { // Firebase Timestamp object with toDate method
              dropDate = drop.dropDate.toDate();
            } else if (typeof drop.dropDate === 'string') {
              dropDate = new Date(drop.dropDate);
            } else {
              console.log("Unknown date format for drop:", drop.id, drop.dropDate);
              return false;
            }
            
            return dropDate > now && (drop.dropComplete !== true);
          } catch (error) {
            console.error(`Error processing date for drop ${drop.id}:`, error);
            return false;
          }
        });
        
        console.log(`Found ${upcomingDrops.length} upcoming drops`);
        setTotalUpcomingDrops(upcomingDrops.length);
        
        // 2. Process all drops for display
        const processedDrops = drops.map(drop => {
          try {
            // Get the trend score field based on timeFrame
            let trendScore;
            if (timeFrame === 'daily') {
              trendScore = drop.runningTrendScore24h || drop.trendScore || 0;
            } else if (timeFrame === 'weekly') {
              trendScore = drop.runningTrendScore7d || drop.trendScore || 0;
            } else if (timeFrame === 'monthly') {
              trendScore = drop.runningTrendScore30d || drop.trendScore || 0;
            } else {
              trendScore = drop.trendScore || 0;
            }
            
            // Format the drop date
            let dropDate;
            try {
              if (drop.dropDate instanceof Timestamp) {
                dropDate = drop.dropDate.toDate();
              } else if (drop.dropDate.seconds) {
                dropDate = new Date(drop.dropDate.seconds * 1000);
              } else if (drop.dropDate.toDate) {
                dropDate = drop.dropDate.toDate();
              } else if (typeof drop.dropDate === 'string') {
                dropDate = new Date(drop.dropDate);
              } else {
                dropDate = new Date(); // Fallback
              }
            } catch (error) {
              console.error(`Error processing date for drop ${drop.id}:`, error);
              dropDate = new Date(); // Fallback
            }
            
            // Get brand information
            let brandName = drop.brand || 'Unknown Brand';
            if (drop.brandId && brandsMap.has(drop.brandId)) {
              const brand = brandsMap.get(drop.brandId);
              brandName = brand.name;
            }
            
            return {
              id: drop.id,
              title: drop.title || 'Unnamed Drop',
              brand: brandName,
              brandId: drop.brandId || null,
              impressions: drop.impressions || 0,
              notifyCount: drop.notifyCount || 0,
              shopLinkClicks: drop.shopLinkClicks || 0,
              trendScore: trendScore,
              image: drop.image || (drop.images && drop.images.length > 0 ? drop.images[0] : null),
              dropDate: dropDate,
            };
          } catch (error) {
            console.error(`Error processing drop ${drop.id}:`, error);
            // Return a default drop object if there was an error
            return {
              id: drop.id,
              title: drop.title || 'Error Processing Drop',
              brand: 'Unknown',
              brandId: null,
              impressions: 0,
              notifyCount: 0,
              shopLinkClicks: 0,
              trendScore: 0,
              image: null,
              dropDate: new Date(),
            };
          }
        });
        
        // 3. Sort drops by trend score for "Most Engaged Drops"
        const sortedByEngagement = [...processedDrops]
          .filter(drop => !isNaN(drop.trendScore))
          .sort((a, b) => b.trendScore - a.trendScore);
        
        // Take top 10
        setEngagedDrops(sortedByEngagement.slice(0, 10));
        
        // 4. Sort drops by notify count for "Most Notified Drops"
        const sortedByNotifyCount = [...processedDrops]
          .filter(drop => !isNaN(drop.notifyCount))
          .sort((a, b) => b.notifyCount - a.notifyCount);
        
        // Take top 10
        setNotifiedDrops(sortedByNotifyCount.slice(0, 10));
        
      } catch (error) {
        console.error('Error fetching drop metrics:', error);
      }
      setLoading(false);
    };
    
    fetchData();
  }, [timeFrame]);
  
  // Determine the metric label based on timeFrame
  const getTrendScoreLabel = () => {
    switch(timeFrame) {
      case 'daily': return 'Daily Trend Score (24h)';
      case 'weekly': return 'Weekly Trend Score (7d)';
      case 'monthly': return 'Monthly Trend Score (30d)';
      default: return 'Trend Score';
    }
  };
  
  return (
    <div>
      <h2 className="text-2xl font-bold mb-6">Drop Metrics</h2>
      
      {/* Total Upcoming Drops */}
      <div className="bg-gray-700 rounded-lg p-4 mb-6">
        <div className="text-xl font-semibold text-avnt-orange">Total Upcoming Drops</div>
        <div className="text-3xl font-bold">{totalUpcomingDrops}</div>
      </div>
      
      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-4">Most Engaged Drops</h3>
        <DropTable 
          drops={engagedDrops} 
          loading={loading}
          caption={`Top 10 drops by overall engagement (${timeFrame})`}
          metricLabel={getTrendScoreLabel()}
        />
      </div>
      
      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-4">Most Notified Drops</h3>
        <DropTable 
          drops={notifiedDrops} 
          loading={loading}
          caption={`Top 10 drops by notify count (${timeFrame})`}
          metricColumn="notifyCount"
          metricLabel="Notify Count"
        />
      </div>
    </div>
  );
};

export default DropMetrics; 