// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBrAVDvwva2fxEMqK7TzmwShNLaO69pONw",
  authDomain: "avnt-shopify-backend.firebaseapp.com",
  projectId: "avnt-shopify-backend",
  storageBucket: "avnt-shopify-backend.firebasestorage.app",
  messagingSenderId: "1098023193444",
  appId: "1:1098023193444:web:a51e42f9f084d3e5618871",
  measurementId: "G-7B20HCVSBZ"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);