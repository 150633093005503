// src/Shop.js
import React, { useEffect, useState } from "react";
import { FirestoreRepository } from "./firebase/FirebaseRepository";

function Shop() {
  const [categorySections, setCategorySections] = useState([]);
  const [loading, setLoading] = useState(true);
  const repository = new FirestoreRepository();

  useEffect(() => {
    async function loadProducts() {
      try {
        setLoading(true);
        const products = await repository.fetchActiveProducts();
        const grouped = groupAndSortByCategory(products);
        setCategorySections(grouped);
      } catch (err) {
        console.error("Failed to load products:", err);
      } finally {
        setLoading(false);
      }
    }
    loadProducts();
  }, []);

  return (
    <div className="min-h-screen bg-black text-white">
      <h1 className="text-3xl font-bold text-center py-4">Shop</h1>
      <div className="bg-black">
        {loading ? (
          <div className="text-center py-10">Loading products...</div>
        ) : categorySections.length > 0 ? (
          categorySections.map((section) => (
            <CategorySection key={section.id} section={section} />
          ))
        ) : (
          <div className="text-center py-10">No products found</div>
        )}
      </div>
    </div>
  );
}

function groupAndSortByCategory(products) {
  const map = {};
  products.forEach((p) => {
    if (!p.category) return;
    if (!map[p.category]) {
      map[p.category] = [];
    }
    map[p.category].push(p);
  });
  
  const sections = Object.keys(map)
    .filter(cat => map[cat].length >= 10) // Only include categories with at least 10 products
    .map((cat) => {
      const sorted = map[cat].sort(
        (a, b) => (b.trendScore || 0) - (a.trendScore || 0)
      );
      const top10 = sorted.slice(0, 10); // Get exactly 10 products
      return { id: `cat-${cat}`, category: cat, products: top10 };
    });
    
  sections.sort((a, b) => a.category.localeCompare(b.category));
  return sections;
}

function CategorySection({ section }) {
  const { category, products } = section;
  const title = `Top 10 in ${category} Today`;
  return (
    <div className="mb-0">
      <h2 className="text-xl font-bold pt-[13px] pl-5">{title}</h2>
      {/* Add "no-scrollbar" class to hide horizontal scroll bar */}
      <div className="overflow-x-auto py-2 no-scrollbar">
        <div className="flex space-x-[30px] px-5">
          {products.map((product, index) => (
            <ProductCard key={product.id} product={product} rank={index + 1} />
          ))}
        </div>
      </div>
    </div>
  );
}

function ProductCard({ product, rank }) {
  const [imageError, setImageError] = useState(false);
  const fallbackImage = "/avntlogo.png"; // Use our local logo as fallback
  
  // Ensure we have product data or use fallback
  const safeProduct = product || {};
  
  // Get image URL with simpler logic
  const imageUrl = imageError || !safeProduct.images?.[0]
    ? fallbackImage 
    : safeProduct.images[0];
    
  const title = safeProduct.title || "Product Name";

  const handleImageError = (e) => {
    console.error("Image failed to load:", e.target.src);
    setImageError(true);
  };

  const handleClick = () => {
    if (safeProduct.shopLink) {
      window.open(safeProduct.shopLink, "_blank");
    }
  };

  return (
    <div
      onClick={handleClick}
      className="relative bg-transparent cursor-pointer"
      style={{ width: "170px", height: "230px" }}
    >
      <div className="absolute top-0" style={{ transform: "translateX(-20px)" }}>
        <OutlinedRank rank={rank} />
      </div>
      <div
        className="relative bg-transparent"
        style={{ transform: "translateX(30px)", width: "130px" }}
      >
        <img
          src={imageUrl}
          alt={title}
          onError={handleImageError}
          className="h-[180px] w-full object-cover rounded-[10px]"
          style={{ backgroundColor: "#111" }}
        />
        <p
          className="text-center text-white font-bold mt-2"
          style={{ fontSize: "13.5px", lineHeight: "1.2" }}
        >
          {title}
        </p>
      </div>
    </div>
  );
}

function OutlinedRank({ rank }) {
  const rankStr = `${rank}`;
  return (
    <div className="relative" style={{ width: "120px", height: "120px" }}>
      <span
        className="absolute text-[120px] font-bold text-white leading-none"
        style={{ transform: "translate(-2px, 0)" }}
      >
        {rankStr}
      </span>
      <span
        className="absolute text-[120px] font-bold text-white leading-none"
        style={{ transform: "translate(2px, 0)" }}
      >
        {rankStr}
      </span>
      <span
        className="absolute text-[120px] font-bold text-white leading-none"
        style={{ transform: "translate(0, -2px)" }}
      >
        {rankStr}
      </span>
      <span
        className="absolute text-[120px] font-bold text-white leading-none"
        style={{ transform: "translate(0, 2px)" }}
      >
        {rankStr}
      </span>
      <span className="absolute text-[120px] font-bold text-black leading-none">
        {rankStr}
      </span>
    </div>
  );
}

export default Shop;
