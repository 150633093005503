import React, { useState, useEffect } from 'react';
import { collection, query, where, orderBy, limit, getDocs, getFirestore, Timestamp } from 'firebase/firestore';
import MetricCard from './components/MetricCard';
import UserTimeTable from './components/UserTimeTable';

const TimeMetrics = ({ timeFrame }) => {
  const [averageTimePerUser, setAverageTimePerUser] = useState(0);
  const [topUsersByTime, setTopUsersByTime] = useState([]);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const db = getFirestore();
        
        // Calculate date range based on timeFrame
        const now = new Date();
        let startDate = new Date();
        
        if (timeFrame === 'daily') {
          startDate.setDate(now.getDate() - 1);
        } else if (timeFrame === 'weekly') {
          startDate.setDate(now.getDate() - 7);
        } else if (timeFrame === 'monthly') {
          startDate.setMonth(now.getMonth() - 1);
        }
        
        const startTimestamp = Timestamp.fromDate(startDate);
        
        // Get user session data for average time calculation
        // Assuming you have a 'userSessions' collection with fields: userId, startTime, endTime
        const sessionsQuery = query(
          collection(db, 'userSessions'),
          where('startTime', '>=', startTimestamp)
        );
        
        const sessionDocs = await getDocs(sessionsQuery);
        
        // Track total session time and unique users
        let totalSessionTime = 0;
        const uniqueUsers = new Set();
        const userTotalTimes = {};
        
        sessionDocs.forEach(doc => {
          const sessionData = doc.data();
          const userId = sessionData.userId;
          
          if (userId && sessionData.startTime && sessionData.endTime) {
            uniqueUsers.add(userId);
            
            // Calculate session duration in minutes
            const startTime = sessionData.startTime.toDate();
            const endTime = sessionData.endTime.toDate();
            const durationMinutes = (endTime - startTime) / (1000 * 60);
            
            // Only count reasonable durations (< 3 hours)
            if (durationMinutes > 0 && durationMinutes < 180) {
              totalSessionTime += durationMinutes;
              
              // Add to user's total time
              if (!userTotalTimes[userId]) {
                userTotalTimes[userId] = {
                  userId,
                  username: sessionData.username || 'Anonymous',
                  totalMinutes: 0,
                  sessionCount: 0
                };
              }
              
              userTotalTimes[userId].totalMinutes += durationMinutes;
              userTotalTimes[userId].sessionCount += 1;
            }
          }
        });
        
        // Calculate average time per user (in minutes)
        const numUniqueUsers = uniqueUsers.size;
        const avgTimePerUser = numUniqueUsers > 0 ? totalSessionTime / numUniqueUsers : 0;
        setAverageTimePerUser(avgTimePerUser);
        
        // Convert user time data to array and sort by total time spent
        const userTimeArray = Object.values(userTotalTimes);
        userTimeArray.sort((a, b) => b.totalMinutes - a.totalMinutes);
        
        // Get top 10 users by time spent
        setTopUsersByTime(userTimeArray.slice(0, 10));
      } catch (error) {
        console.error('Error fetching time metrics:', error);
      }
      setLoading(false);
    };
    
    fetchData();
  }, [timeFrame]);
  
  // Format minutes into hours and minutes
  const formatTime = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = Math.round(minutes % 60);
    return `${hours}h ${mins}m`;
  };
  
  return (
    <div>
      <h2 className="text-2xl font-bold mb-6">Time Metrics</h2>
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
        <MetricCard 
          title="Average Time per User" 
          value={loading ? '...' : formatTime(averageTimePerUser)} 
          description={`${timeFrame === 'daily' ? 'Today' : timeFrame === 'weekly' ? 'This week' : 'This month'}`}
          icon="⏱️"
        />
      </div>
      
      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-4">Top 10 Users by Time Spent</h3>
        <UserTimeTable 
          users={topUsersByTime} 
          loading={loading}
          formatTime={formatTime}
          caption={`Users ranked by time spent on app (${timeFrame})`}
        />
      </div>
    </div>
  );
};

export default TimeMetrics; 