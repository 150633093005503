import React from 'react';

const BrandTable = ({ brands, loading, caption, metricColumn, metricLabel }) => {
  if (loading) {
    return <div className="text-center py-8 text-gray-400">Loading brand data...</div>;
  }

  if (!brands || brands.length === 0) {
    return <div className="text-center py-8 text-gray-400">No brand data available</div>;
  }

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-gray-700 rounded-lg overflow-hidden">
        <caption className="sr-only">{caption}</caption>
        <thead className="bg-gray-600">
          <tr>
            <th className="px-4 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Rank</th>
            <th className="px-4 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Brand</th>
            <th className="px-4 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">{metricLabel}</th>
            <th className="px-4 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Engagement Stats</th>
            <th className="px-4 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Actions</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-600">
          {brands.map((brand, index) => (
            <tr key={brand.id} className={index % 2 === 0 ? 'bg-gray-700' : 'bg-gray-750'}>
              <td className="px-4 py-3 whitespace-nowrap">{index + 1}</td>
              <td className="px-4 py-3 whitespace-nowrap">
                <div className="flex items-center">
                  {brand.logo && (
                    <div className="flex-shrink-0 h-10 w-10 rounded bg-gray-600">
                      <img
                        className="h-10 w-10 rounded object-cover"
                        src={brand.logo}
                        alt={brand.name}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = 'https://via.placeholder.com/40?text=AVNT';
                        }}
                      />
                    </div>
                  )}
                  <div className="ml-3">
                    <div className="text-sm font-medium text-white">{brand.name}</div>
                  </div>
                </div>
              </td>
              <td className="px-4 py-3 whitespace-nowrap">
                <div className="text-sm font-medium text-white">
                  {(brand[metricColumn] || 0).toLocaleString()}
                </div>
              </td>
              <td className="px-4 py-3 whitespace-nowrap">
                <div className="text-xs text-gray-300">
                  <div>Views: {(brand.views || 0).toLocaleString()}</div>
                  <div>Shop Clicks: {(brand.clicks || 0).toLocaleString()}</div>
                  <div>Follows: {(brand.follows || 0).toLocaleString()}</div>
                </div>
              </td>
              <td className="px-4 py-3 whitespace-nowrap text-sm">
                <a 
                  href={brand.websiteUrl} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-avnt-orange hover:text-orange-400"
                >
                  Visit Website
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default BrandTable; 