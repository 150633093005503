// src/Invites.js
import React, { useState } from "react";
import { db } from "./firebase";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";

function Invites() {
  const [email, setEmail] = useState("");
  const [responseMsg, setResponseMsg] = useState("");

  // Handle the invite request using the Firebase SDK directly
  const handleInviteRequest = async () => {
    if (!email) {
      setResponseMsg("Please enter a valid email address.");
      return;
    }
    try {
      await addDoc(collection(db, "invites"), {
        email,
        createdAt: serverTimestamp(),
      });
      setResponseMsg("Invite request submitted successfully!");
      setEmail("");
    } catch (err) {
      console.error("Error saving invite:", err);
      setResponseMsg("Error saving invite. Please try again.");
    }
  };

  return (
    <div className="bg-black min-h-screen text-white flex flex-col items-center px-4 py-12">
      {/* Page Title */}
      <h1 className="text-2xl md:text-3xl font-bold mb-6 md:mb-8 text-center">
        Request an invite
      </h1>

      {/* Dark Gray Box */}
      <div className="rounded-[15px] shadow-md bg-[#111] w-full max-w-2xl mx-auto p-6 md:p-8">
        <h2 className="text-xl md:text-2xl font-semibold mb-2 md:mb-4">
          Interested in having your brand on AVNT?
        </h2>
        <p className="text-base md:text-lg mb-4 md:mb-6">
          To request an invite, provide your email address.
        </p>

        {/* Email + Button */}
        <div className="flex flex-col sm:flex-row items-center sm:items-stretch sm:space-x-2">
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="
              w-full sm:w-auto flex-grow
              mb-4 sm:mb-0
              border border-white
              bg-[#333]
              text-white
              placeholder-gray-500
              rounded
              p-3 md:p-4
              text-base md:text-lg
            "
          />
          <button
            onClick={handleInviteRequest}
            className="
              bg-[#ff9500]
              text-black
              px-4 md:px-6
              py-2 md:py-3
              text-base md:text-lg
              rounded
              font-semibold
              hover:bg-[#ffa733]
            "
          >
            Request Invite
          </button>
        </div>

        {/* Response Message */}
        {responseMsg && (
          <p className="mt-4 text-base md:text-lg">
            {responseMsg}
          </p>
        )}
      </div>
    </div>
  );
}

export default Invites;
