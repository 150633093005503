/**
 * Performance metrics for AVNT site
 * Based on Web Vitals methodology: https://web.dev/vitals/
 */

export const measureCLS = (onReport) => {
  let clsValue = 0;
  let clsEntries = [];

  const observer = new PerformanceObserver((entryList) => {
    for (const entry of entryList.getEntries()) {
      // Only count layout shifts without recent user input
      if (!entry.hadRecentInput) {
        clsValue += entry.value;
        clsEntries.push(entry);
      }
    }

    // Report CLS value
    if (onReport) {
      onReport({ name: 'CLS', value: clsValue });
    }
  });

  observer.observe({ type: 'layout-shift', buffered: true });
  return () => {
    observer.disconnect();
  };
};

export const measureLCP = (onReport) => {
  const observer = new PerformanceObserver((entryList) => {
    const entries = entryList.getEntries();
    const lastEntry = entries[entries.length - 1];
    
    if (onReport) {
      onReport({ name: 'LCP', value: lastEntry.startTime });
    }
  });

  observer.observe({ type: 'largest-contentful-paint', buffered: true });
  return () => {
    observer.disconnect();
  };
};

export const measureFID = (onReport) => {
  const observer = new PerformanceObserver((entryList) => {
    const entries = entryList.getEntries();
    
    for (const entry of entries) {
      if (onReport) {
        onReport({ name: 'FID', value: entry.processingStart - entry.startTime });
      }
    }
  });

  observer.observe({ type: 'first-input', buffered: true });
  return () => {
    observer.disconnect();
  };
};

export const reportWebVitals = ({ name, value }) => {
  // Log to console during development
  if (process.env.NODE_ENV !== 'production') {
    console.log(`${name}: ${Math.round(value * 1000) / 1000}`);
  }
  
  // In production, you'd send this to your analytics service
  // Example using Google Analytics:
  // if (window.gtag) {
  //   window.gtag('event', name, {
  //     value: Math.round(name === 'CLS' ? value * 1000 : value),
  //     event_category: 'Web Vitals',
  //     event_label: 'User Experience',
  //     non_interaction: true,
  //   });
  // }
};

export const initPerformanceMonitoring = () => {
  // Start measuring Core Web Vitals
  const stopCLS = measureCLS(reportWebVitals);
  const stopLCP = measureLCP(reportWebVitals);
  const stopFID = measureFID(reportWebVitals);
  
  // Return a function to clean up observers
  return () => {
    stopCLS();
    stopLCP();
    stopFID();
  };
}; 