import React from 'react';

const ProductTable = ({ products, loading, caption, metricColumn = 'trendScore', metricLabel = 'Engagement', showShopClicks = false }) => {
  if (loading) {
    return <div className="text-center py-8 text-gray-400">Loading product data...</div>;
  }

  if (!products || products.length === 0) {
    return <div className="text-center py-8 text-gray-400">No product data available</div>;
  }

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-gray-700 rounded-lg overflow-hidden">
        <caption className="sr-only">{caption}</caption>
        <thead className="bg-gray-600">
          <tr>
            <th className="px-4 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Rank</th>
            <th className="px-4 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Product</th>
            <th className="px-4 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Brand</th>
            <th className="px-4 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">{metricLabel}</th>
            {showShopClicks && metricColumn !== 'shopLinkClicks' && (
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Shop Clicks</th>
            )}
            <th className="px-4 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Actions</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-600">
          {products.map((product, index) => (
            <tr key={product.id} className={index % 2 === 0 ? 'bg-gray-700' : 'bg-gray-750'}>
              <td className="px-4 py-3 whitespace-nowrap">{index + 1}</td>
              <td className="px-4 py-3 whitespace-nowrap">
                <div className="flex items-center">
                  {product.image && (
                    <div className="flex-shrink-0 h-10 w-10 rounded bg-gray-600">
                      <img
                        className="h-10 w-10 rounded object-cover"
                        src={product.image}
                        alt={product.title}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = 'https://via.placeholder.com/40?text=AVNT';
                        }}
                      />
                    </div>
                  )}
                  <div className="ml-3">
                    <div className="text-sm font-medium text-white">{product.title}</div>
                    <div className="text-xs text-gray-400">
                      <span className="mr-2">Views: {product.impressions.toLocaleString()}</span>
                      {product.expandedViewClicks > 0 && (
                        <span className="mr-2">Expanded: {product.expandedViewClicks.toLocaleString()}</span>
                      )}
                      <span className="mr-2">Shop Clicks: {product.shopLinkClicks.toLocaleString()}</span>
                    </div>
                  </div>
                </div>
              </td>
              <td className="px-4 py-3 whitespace-nowrap">
                <div className="text-sm text-white">{product.brand}</div>
              </td>
              <td className="px-4 py-3 whitespace-nowrap">
                <div className="text-sm font-medium text-white">
                  {product[metricColumn].toLocaleString()}
                </div>
              </td>
              {showShopClicks && metricColumn !== 'shopLinkClicks' && (
                <td className="px-4 py-3 whitespace-nowrap">
                  <div className="text-sm font-medium text-white">
                    {product.shopLinkClicks.toLocaleString()}
                  </div>
                </td>
              )}
              <td className="px-4 py-3 whitespace-nowrap text-sm">
                <a 
                  href={product.productLink} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-avnt-orange hover:text-orange-400"
                >
                  View Product
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProductTable; 